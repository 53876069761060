import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { TransportUserProfile } from '../../shared/api/Api';

const initialState: { isSignOut: boolean; user: TransportUserProfile | null } =
	{
		isSignOut: false,
		user: null,
	};

export const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setUser(state, action: PayloadAction<TransportUserProfile>) {
			state.user = action.payload;
		},
		deleteUser(state) {
			state.isSignOut = true;
			state.user = null;
		},
	},
});

export default userSlice.reducer;
