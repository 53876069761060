import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import calculatorIcon from '../../../../assets/icons/calculatorIcon.svg';
import ButtonComponent from '../../../../components/Common/Button/ButtonComponent';
import {
	DomainCashierBalances,
	TransportCashierDevicePlace,
} from '../../../../shared/api/Api';
import colorOnPlace from '../../../../utils/colorOnPlace';
import CashManageModal from '../CashManageModal/CashManageModal';
import './styles.scss';

const DeviceDetailTab: React.FC<
	TransportCashierDevicePlace & {
		setCurrentDetailsUndefined: () => void;
		refetchDetails: () => void;
	}
> = ({
	balances,
	place,
	device,
	setCurrentDetailsUndefined,
	refetchDetails,
}) => {
	const { t } = useTranslation();

	const isDesktop = useMediaQuery({ query: '(min-width: 1351px)' });

	const formattedBalance = ((balances?.entries ?? 0) / 100).toFixed(2);

	const [showCashManage, setShowCashManage] = useState<boolean>(false);
	const handleShowCashManage = () => setShowCashManage(!showCashManage);

	return (
		<>
			<CashManageModal
				show={showCashManage}
				handler={handleShowCashManage}
				deviceId={device}
				placeId={place}
				delta={balances as DomainCashierBalances}
				setCurrentDetailsUndefined={setCurrentDetailsUndefined}
				refetchDetails={refetchDetails}
			/>
			<div className='deviceDetailTab' onClick={handleShowCashManage}>
				<p>
					{t('devices.detailsTab.player')} #{place}
				</p>
				<div className='colorWrapper'>
					<div
						className={`color ${colorOnPlace(place as 1 | 2 | 3 | 4 | 5 | 6)}`}
					/>
				</div>
				<p>{formattedBalance}</p>
				{isDesktop && (
					<div className='btnWrapper'>
						<ButtonComponent
							title={t('devices.detailsTab.manage')}
							icon={calculatorIcon}
							iconAlt='calculatorIcon'
							className='manageBtn'
							color='white'
							action={handleShowCashManage}
						/>
					</div>
				)}
			</div>
		</>
	);
};

export default DeviceDetailTab;
