import en from './en';
import es from './es';
import pt from './pt';

const resources = {
	en,
	es,
	pt,
};

export default resources;
