const colorOnPlace = (place: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8): string => {
	const colors = {
		1: 'yellow',
		2: 'blue',
		3: 'orange',
		4: 'purple',
		5: 'green',
		6: 'red',
		7: 'cyan',
		8: 'black',
	};
	return colors[place];
};

export default colorOnPlace;
