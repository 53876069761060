import React from 'react';

// import percentArrow from '../../../../assets/icons/percentStatArrowIcon.svg';
import { IDailyStatisticsTab } from '../../../../utils/types';
import './styles.scss';

const DailyStatisticsTab: React.FC<IDailyStatisticsTab> = ({
	title,
	count,
	deposit,
	// percent,
}) => {
	return (
		<div className='dailyStatisticTab'>
			<div className='title'>
				<p>{title}</p>
			</div>
			<hr />
			<div className='numberStats'>
				<p
					className={`${deposit ? 'green' : 'red'}`}
					style={count.length > 13 ? { fontSize: '12px' } : {}}>
					{count}
				</p>
				{/* <div className='percentStat'>
					<img
						src={percentArrow}
						className='percentArrow'
						alt='percentArrowIcon'
					/>
					<p>{percent}%</p>
				</div> */}
			</div>
		</div>
	);
};

export default DailyStatisticsTab;
