export const cashManageNums = [
	'7',
	'8',
	'9',
	'4',
	'5',
	'6',
	'1',
	'2',
	'3',
	'0',
	'.',
];

export const quickNumbers = ['100', '50', '20', '10'];
