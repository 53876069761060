import React from 'react';

import ReactDOM from 'react-dom/client';

import App from './components/App/App';
import './i18n';
import 'react-toastify/dist/ReactToastify.css';
import 'react-calendar-datetime-picker/dist/style.css';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import './index.scss';

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);
root.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>
);
