import { combineReducers, configureStore } from '@reduxjs/toolkit';

import { baseAPI } from '../services/baseAPI';
import userReducer from './reducers/UserSlice';

const rootReducer = combineReducers({
	[baseAPI.reducerPath]: baseAPI.reducer,
	userReducer,
});

export const setupStore = () => {
	return configureStore({
		reducer: rootReducer,
		middleware: (getDefaultMiddleware) =>
			getDefaultMiddleware().concat(baseAPI.middleware),
	});
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
