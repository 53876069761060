import React from 'react';

import { Navbar } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

import AuthorizationNavbar from './components/AuthorizationNavbar/AuthorizationNavbar';
import CashierNavbar from './components/CashierNavbar/CashierNavbar';
import './styles.scss';

const NavbarComponent: React.FC = () => {
	const { pathname } = useLocation();

	return (
		<header>
			<Navbar
				className={`navbarComponent serviceBlock ${
					pathname === '/authorization' || pathname === '/authorization/'
						? 'authorization'
						: 'cashdesk'
				}`}>
				{pathname === '/authorization' || pathname === '/authorization/' ? (
					<AuthorizationNavbar />
				) : (
					<CashierNavbar />
				)}
			</Navbar>
		</header>
	);
};

export default NavbarComponent;
