import React, { RefObject } from 'react';

import { Accordion } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { LineWave } from 'react-loader-spinner';
import { useMediaQuery } from 'react-responsive';

import { DomainAccountOperationReportResult } from '../../../../shared/api/Api';
import HistoryTab from '../HistoryTab/HistoryTab';
import './styles.scss';

interface IHistoryTable {
	contentRef: RefObject<HTMLDivElement>;
	isFetching: boolean;
	operationType: 'all' | 'deposit' | 'withdraw';
	history?: DomainAccountOperationReportResult[];
	depositHistories?: DomainAccountOperationReportResult[];
	withdrawHistories?: DomainAccountOperationReportResult[];
	utc: string;
}

const HistoryTable: React.FC<IHistoryTable> = ({
	contentRef,
	isFetching,
	operationType,
	history,
	depositHistories,
	withdrawHistories,
	utc,
}) => {
	const isDesktop = useMediaQuery({ query: '(min-width: 1351px)' });
	const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1350 });
	const isMobile = useMediaQuery({ maxWidth: 767 });
	const { t } = useTranslation();

	const histories =
		operationType === 'all'
			? history
			: operationType === 'deposit'
			? depositHistories
			: withdrawHistories;

	return (
		<div
			className={`historyTable ${
				isDesktop ? 'desktop' : isTablet ? 'tablet' : 'mobile'
			}`}
			ref={contentRef}>
			<div className='titles'>
				<p className='deviceName'>
					{!isMobile
						? t('history.titles.deviceName')
						: t('modals.editDevice.mainForm.device')}
				</p>
				{!isMobile && (
					<p className='deviceId'>{t('history.titles.deviceId')}</p>
				)}
				{!isMobile && <p className='player'>{t('history.titles.player')}</p>}
				{!isMobile && (
					<p className='transactionId'>{t('history.titles.transactionId')}</p>
				)}
				{isMobile && <p className='type'>{t('history.titles.type')}</p>}
				<p className='amount'>{t('history.titles.amount')}</p>
				{isMobile && <p className='player'>{t('history.titles.player')}</p>}
				{!isMobile && <p className='type'>{t('history.titles.type')}</p>}
				<p className='date'>{t('history.titles.date')}</p>
			</div>
			{!isMobile ? (
				<div
					className={`historyTabs ${
						history && history.length === 0 ? 'noPlaces' : ''
					}`}>
					{isFetching ? (
						<LineWave
							height='100'
							width='100'
							ariaLabel='line-wave-loading'
							wrapperClass='lineWave'
							firstLineColor='#EB2F2F'
							middleLineColor='#3BBB26'
							lastLineColor='#3262DE'
						/>
					) : history && history.length === 0 ? (
						<p>{t('devices.detailsTab.noPlaces.history')}</p>
					) : operationType === 'all' ? (
						history?.map((story, index) => (
							<HistoryTab key={index} {...story} utc={utc} />
						))
					) : operationType === 'deposit' ? (
						depositHistories?.map((story, index) => (
							<HistoryTab key={index} {...story} utc={utc} />
						))
					) : (
						operationType === 'withdraw' &&
						withdrawHistories?.map((story, index) => (
							<HistoryTab key={index} {...story} utc={utc} />
						))
					)}
				</div>
			) : (
				<Accordion
					className={`historyTabs ${
						history && history.length === 0 ? 'noPlaces' : ''
					}`}>
					{isFetching ? (
						<LineWave
							height='100'
							width='100'
							ariaLabel='line-wave-loading'
							wrapperClass='lineWave'
							firstLineColor='#EB2F2F'
							middleLineColor='#3BBB26'
							lastLineColor='#3262DE'
						/>
					) : history && history.length === 0 ? (
						<p>{t('devices.detailsTab.noPlaces.history')}</p>
					) : (
						histories?.map((story, index) => (
							<HistoryTab key={index} {...story} utc={utc} />
						))
					)}
				</Accordion>
			)}
		</div>
	);
};

export default HistoryTable;
