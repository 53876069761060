import React, { useState } from 'react';

import { useFormik } from 'formik';
import { Form, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { LineWave } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import eye from '../../../../assets/icons/eyeIcon.svg';
import eyeSlash from '../../../../assets/icons/eyeSlashIcon.svg';
import ButtonComponent from '../../../../components/Common/Button/ButtonComponent';
import InputWrapper from '../../../../components/Common/InputWrapper/InputWrapper';
import { useAppDispatch } from '../../../../hooks/redux';
import { baseAPI } from '../../../../services/baseAPI';
import { userAPI } from '../../../../services/UserService';
import { TransportUserChangerPasswordRequest } from '../../../../shared/api/Api';
import showToastOnce from '../../../../utils/showToastOnce';
import { IModalProps } from '../../../../utils/types';
import './styles.scss';

const ChangePasswordModal: React.FC<IModalProps> = ({ show, handler }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const [showOldPassword, setShowOldPassword] = useState(false);
	const [showNewPassword, setShowNewPassword] = useState(false);
	const [showNewRepeatedPassword, setShowNewRepeatedPassword] = useState(false);

	const [changePassword, { isLoading }] = userAPI.useChangePasswordMutation();

	const validationSchema: yup.ObjectSchema<
		TransportUserChangerPasswordRequest & { repeat: string }
	> = yup.object().shape({
		old: yup
			.string()
			.required(t('modals.changePassword.errors.required', { type: 'Old' }))
			.max(64, t('modals.changePassword.errors.max', { type: 'Old' })),
		new: yup
			.string()
			.required(t('modals.changePassword.errors.required', { type: 'New' }))
			.max(64, t('modals.changePassword.errors.max', { type: 'New' })),
		repeat: yup
			.string()
			.required(
				t('modals.changePassword.errors.required', { type: 'Repeated' })
			)
			.max(64, t('modals.changePassword.errors.max', { type: 'Repeated' }))
			.oneOf([yup.ref('new')], t('modals.changePassword.errors.mismatch')),
	});

	const formik = useFormik({
		initialValues: {
			old: '',
			new: '',
			repeat: '',
		},
		validationSchema,
		onSubmit: async (
			values: TransportUserChangerPasswordRequest & { repeat: string }
		) => {
			const clearedValues = {
				old: values.old,
				new: values.new,
			};

			try {
				const response = await changePassword(clearedValues).unwrap();
				if (response.ok) {
					handleClose();
					showToastOnce(
						'success',
						t('toasts.success.changedPassword'),
						'changedPassword'
					);
				}
			} catch (error: any) {
				if (error.status === 401) {
					navigate('/authorization');
					showToastOnce(
						'error',
						t('toasts.error.cookieExpired'),
						'cookieExpired'
					);
					return () => {
						dispatch(baseAPI.util.resetApiState());
					};
				}
			}
		},
	});

	const handleClose = () => {
		handler();
		formik.resetForm();
	};

	return (
		<Modal
			show={show}
			onHide={isLoading ? () => undefined : handleClose}
			centered
			className='changePasswordModal'>
			<Modal.Body>
				{isLoading ? (
					<LineWave
						height='100'
						width='100'
						ariaLabel='line-wave-loading'
						wrapperClass='lineWave'
						firstLineColor='#EB2F2F'
						middleLineColor='#3BBB26'
						lastLineColor='#3262DE'
					/>
				) : (
					<Form className='mainForm' onSubmit={formik.handleSubmit}>
						<h2>{t('modals.changePassword.title')}</h2>
						<InputWrapper
							name='old'
							placeholderRoute='modals.changePassword'
							icon={showOldPassword ? eye : eyeSlash}
							iconAlt='showPasswordIcon'
							formik={formik}
							type={showOldPassword ? 'text' : 'password'}
							action={() => setShowOldPassword(!showOldPassword)}
						/>
						<InputWrapper
							name='new'
							placeholderRoute='modals.changePassword'
							icon={showNewPassword ? eye : eyeSlash}
							iconAlt='showPasswordIcon'
							formik={formik}
							type={showNewPassword ? 'text' : 'password'}
							action={() => setShowNewPassword(!showNewPassword)}
						/>
						<InputWrapper
							name='repeat'
							placeholderRoute='modals.changePassword'
							icon={showNewRepeatedPassword ? eye : eyeSlash}
							iconAlt='showPasswordIcon'
							formik={formik}
							type={showNewRepeatedPassword ? 'text' : 'password'}
							action={() =>
								setShowNewRepeatedPassword(!showNewRepeatedPassword)
							}
						/>
						<div className='btns'>
							<ButtonComponent
								title={t('modals.editDevice.mainForm.btns.cancel')}
								className='backBtn'
								color='white'
								action={handleClose}
							/>
							<ButtonComponent
								title={t('modals.editDevice.mainForm.btns.save')}
								className='okBtn'
								color='blue'
								type='submit'
							/>
						</div>
					</Form>
				)}
			</Modal.Body>
		</Modal>
	);
};

export default ChangePasswordModal;
