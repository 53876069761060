import { t } from 'i18next';

import { baseAPI } from './baseAPI';
import {
	Api,
	TransportCashierDevicePlace,
	DomainCashierDevice,
	TransportCashierBalanceResponse,
	TransportModBalanceRequest,
	TransportRegisterDeviceRequest,
	TransportUpdateDeviceRequest,
	TransportOperationReportRequest,
	DomainAccountOperationReportResult,
	DomainFloatBalanceResult,
} from '../shared/api/Api';
import showToastOnce from '../utils/showToastOnce';

const api = new Api({
	withCredentials: true,
	baseURL: `/api/v1`,
});

export const cashierAPI = baseAPI.injectEndpoints({
	endpoints: (build) => ({
		fetchDevices: build.query<DomainCashierDevice[], string>({
			async queryFn() {
				const maxRetries = 2;
				let attempt = 0;

				while (attempt <= maxRetries) {
					try {
						const response = await api.a.cashierDevicesList();
						const sortedData = response.data
							// .filter((device) => device.active)
							.sort((a, b) => {
								return (
									new Date(a.created_at ?? '').getTime() -
									new Date(b.created_at ?? '').getTime()
								);
							});
						return { data: sortedData };
					} catch (error: any) {
						if (error.response.status === 500 && attempt < maxRetries) {
							attempt += 1;
							// await new Promise((resolve) => setTimeout(resolve, 1000));
						} else {
							// error handling
							if (error.response.data?.err === 'timeout') {
								showToastOnce('error', t('toasts.error.timeout'), 'timeout');
							} else if (error.response.status === 401) {
								return {
									error: {
										status: error.response.status,
										error: error.message,
									},
								};
							} else if (error.response.status === 403) {
								if (error.response.data.err === 'user dont have account') {
									showToastOnce(
										'error',
										t('toasts.requestErrors.noAccount'),
										'noAccount'
									);
								} else if (error.response.data.err === 'Forbidden') {
									showToastOnce(
										'error',
										t('toasts.error.forbidden'),
										'forbidden'
									);
								} else {
									showToastOnce(
										'error',
										error.response.data.err,
										error.response.data.err
									);
								}
							} else {
								showToastOnce(
									'error',
									error.response.data.err,
									error.response.data.err
								);
							}
							// stop the cycle after all errors
							break;
						}
					}
				}

				/* // if we got max attempts
				if (attempt > maxRetries) {
					return {
						error: {
							status: 'CUSTOM_ERROR',
							error: 'Attempts are out',
						},
					};
				} */

				// returns the value if the error is unknown
				return {
					error: {
						status: 'CUSTOM_ERROR',
						error: 'Uknown error',
					},
				};
			},
			providesTags: () => ['Devices' as never],
		}),
		fetchDeviceDetail: build.query<TransportCashierBalanceResponse, string>({
			async queryFn(id) {
				const maxRetries = 2;
				let attempt = 0;

				while (attempt <= maxRetries) {
					try {
						const response = await api.a.cashierDevicesDetail(id);
						return { data: response.data };
					} catch (error: any) {
						if (error.response.status === 500 && attempt < maxRetries) {
							attempt += 1;
							// await new Promise((resolve) => setTimeout(resolve, 1000));
						} else {
							// error handling
							if (error.response.data.err === 'timeout') {
								showToastOnce('error', t('toasts.error.timeout'), 'timeout');
							} else if (error.response.status === 401) {
								return {
									error: {
										status: error.response.status,
										error: error.message,
									},
								};
							} else if (error.response.status === 403) {
								if (error.response.data.err === 'Forbidden') {
									showToastOnce(
										'error',
										t('toasts.error.forbidden'),
										'forbidden'
									);
								} else {
									showToastOnce(
										'error',
										error.response.data.err,
										error.response.data.err
									);
								}
							} else {
								showToastOnce(
									'error',
									error.response.data.err,
									error.response.data.err
								);
							}
							// stop the cycle after all errors
							break;
						}
					}
				}

				/* // if we got max attempts
				if (attempt > maxRetries) {
					return {
						error: {
							status: 'CUSTOM_ERROR',
							error: 'Attempts are out',
						},
					};
				} */

				// returns the value if the error is unknown
				return {
					error: {
						status: 'CUSTOM_ERROR',
						error: 'Uknown error',
					},
				};
			},
			providesTags: () => ['Details' as never],
		}),
		addDevice: build.mutation<
			DomainCashierDevice,
			TransportRegisterDeviceRequest
		>({
			async queryFn(request) {
				const maxRetries = 2;
				let attempt = 0;

				while (attempt <= maxRetries) {
					try {
						const response = await api.a.cashierDevicesRegisterCreate(request);
						return { data: response.data };
					} catch (error: any) {
						if (error.response.status === 500 && attempt < maxRetries) {
							attempt += 1;
							// await new Promise((resolve) => setTimeout(resolve, 1000));
							// error handling
						} else {
							if (error.response.data.err === 'timeout') {
								showToastOnce('error', t('toasts.error.timeout'), 'timeout');
							} else if (error.response?.status === 401) {
								return {
									error: {
										status: error.response.status,
										error: error.message,
									},
								};
							} else if (error.response.status === 403) {
								if (error.response.data.err === 'Forbidden') {
									return {
										error: {
											status: error.response.data.err,
											error: error.message,
										},
									};
								} else {
									showToastOnce(
										'error',
										error.response.data.err,
										error.response.data.err
									);
								}
							} else {
								return {
									error: {
										status: error.response.data.err,
										error: error.message,
									},
								};
							}
							// stop the cycle after all errors
							break;
						}
					}
				}

				/* // if we got max attempts
				if (attempt > maxRetries) {
					return {
						error: {
							status: 'CUSTOM_ERROR',
							error: 'Attempts are out',
						},
					};
				} */

				// returns the value if the error is unknown
				return {
					error: {
						status: 'CUSTOM_ERROR',
						error: 'Uknown error',
					},
				};
			},
			invalidatesTags: () => ['Devices' as never],
		}),
		modifyCash: build.mutation<
			TransportCashierDevicePlace,
			{
				deviceId: string;
				placeId: number;
				request: TransportModBalanceRequest;
				operationType: string;
			}
		>({
			async queryFn({ deviceId, placeId, request, operationType }) {
				const maxRetries = 2;
				let attempt = 0;

				while (attempt <= maxRetries) {
					try {
						const response = await api.a.cashierDevicesPlaceUpdateCreate(
							deviceId,
							placeId,
							request
						);
						showToastOnce(
							'success',
							t(`toasts.success.${operationType}`),
							operationType
						);
						return { data: response.data };
					} catch (error: any) {
						if (error.response.status === 500 && attempt < maxRetries) {
							attempt += 1;
							// await new Promise((resolve) => setTimeout(resolve, 1000));
						} else {
							// error handling
							if (error.response.data.err === 'timeout') {
								showToastOnce('error', t('toasts.error.timeout'), 'timeout');
							} else if (error.response?.status === 401) {
								return {
									error: {
										status: error.response.status,
										error: error.message,
									},
								};
							} else if (error.response.status === 403) {
								if (error.response.data.err === 'Forbidden') {
									showToastOnce(
										'error',
										t('toasts.error.forbidden'),
										'forbidden'
									);
								} else {
									showToastOnce(
										'error',
										error.response.data.err,
										error.response.data.err
									);
								}
							} else if (error.response.status === 409) {
								return {
									error: {
										status: error.response.status,
										error: error.message,
									},
								};
							} else if (error.response.data.err === 'not enough money') {
								return {
									error: {
										status: error.response.data.err,
										error: error.message,
									},
								};
							} else if (error.response.data.err === 'negative balance') {
								return {
									error: {
										status: error.response.data.err,
										error: error.message,
									},
								};
							} else if (
								error.response.data.err === 'min transaction amount exceeded'
							) {
								return {
									error: {
										status: error.response.data,
										error: error.message,
									},
								};
							} else if (
								error.response.data.err === 'max transaction amount exceeded'
							) {
								return {
									error: {
										status: error.response.data,
										error: error.message,
									},
								};
							} else {
								showToastOnce(
									'error',
									error.response.data.err,
									error.response.data.err
								);
							}
							// stop the cycle after all errors
							break;
						}
					}
				}

				/* // if we got max attempts
				if (attempt > maxRetries) {
					return {
						error: {
							status: 'CUSTOM_ERROR',
							error: 'Attempts are out',
						},
					};
				} */

				// returns the value if the error is unknown
				return {
					error: {
						status: 'CUSTOM_ERROR',
						error: 'Uknown error',
					},
				};
			},
			invalidatesTags: () => [
				'Details' as never,
				'Float' as never,
				'Report' as never,
			],
		}),
		editDevice: build.mutation<
			DomainCashierDevice,
			{ deviceId: string; request: TransportUpdateDeviceRequest }
		>({
			async queryFn({ deviceId, request }) {
				const maxRetries = 2;
				let attempt = 0;

				while (attempt <= maxRetries) {
					try {
						const response = await api.a.cashierDevicesCreate(
							deviceId,
							request
						);
						return { data: response.data };
					} catch (error: any) {
						if (error.response.status === 500 && attempt < maxRetries) {
							attempt += 1;
							//	await new Promise((resolve) => setTimeout(resolve, 1000));
							// error handling
						} else {
							if (error.response.data.err === 'timeout') {
								showToastOnce('error', t('toasts.error.timeout'), 'timeout');
							} else if (error.response?.status === 401) {
								return {
									error: {
										status: error.response.status,
										error: error.message,
									},
								};
							} else if (error.response.status === 403) {
								if (error.response.data.err === 'Forbidden') {
									showToastOnce(
										'error',
										t('toasts.error.forbidden'),
										'forbidden'
									);
								} else {
									showToastOnce(
										'error',
										error.response.data.err,
										error.response.data.err
									);
								}
							} else {
								showToastOnce(
									'error',
									error.response.data.err,
									error.response.data.err
								);
							}
							// stop the cycle after all errors
							break;
						}
					}
				}

				/* // if we got max attempts
				if (attempt > maxRetries) {
					return {
						error: {
							status: 'CUSTOM_ERROR',
							error: 'Attempts are out',
						},
					};
				} */

				// returns the value if the error is unknown
				return {
					error: {
						status: 'CUSTOM_ERROR',
						error: 'Uknown error',
					},
				};
			},
			invalidatesTags: () => ['Devices' as never],
		}),
		fetchDeviceReport: build.query<
			DomainAccountOperationReportResult[],
			TransportOperationReportRequest
		>({
			async queryFn(request) {
				const maxRetries = 2;
				let attempt = 0;

				while (attempt <= maxRetries) {
					try {
						const response = await api.a.reportsOperationDeviceCreate(request);
						if (response.data === null) return { data: [] };
						const sortedData = response.data.sort((a, b) => {
							return (
								new Date(b.created_at ?? '').getTime() -
								new Date(a.created_at ?? '').getTime()
							);
						});
						return { data: sortedData };
					} catch (error: any) {
						if (error.response.status === 500 && attempt < maxRetries) {
							attempt += 1;
							// await new Promise((resolve) => setTimeout(resolve, 1000));
						} else {
							// error handling
							if (error.response.data.err === 'timeout') {
								showToastOnce('error', t('toasts.error.timeout'), 'timeout');
							} else if (error.response?.status === 401) {
								return {
									error: {
										status: error.response.status,
										error: error.message,
									},
								};
							} else if (error.response.status === 403) {
								if (error.response.data.err === 'Forbidden') {
									showToastOnce(
										'error',
										t('toasts.error.forbidden'),
										'forbidden'
									);
								} else {
									showToastOnce(
										'error',
										error.response.data.err,
										error.response.data.err
									);
								}
							} else {
								showToastOnce(
									'error',
									error.response.data.err,
									error.response.data.err
								);
							}
							// stop the cycle after all errors
							break;
						}
					}
				}

				/* // if we got max attempts
				if (attempt > maxRetries) {
					return {
						error: {
							status: 'CUSTOM_ERROR',
							error: 'Attempts are out',
						},
					};
				} */

				// returns the value if the error is unknown
				return {
					error: {
						status: 'CUSTOM_ERROR',
						error: 'Uknown error',
					},
				};
			},
			providesTags: () => ['Report' as never],
		}),
		fetchFloat: build.query<DomainFloatBalanceResult, string>({
			async queryFn() {
				const maxRetries = 2;
				let attempt = 0;

				while (attempt <= maxRetries) {
					try {
						const response = await api.a.cashierAccountBalanceList();
						return { data: response.data };
					} catch (error: any) {
						if (error.response.status === 500 && attempt < maxRetries) {
							attempt += 1;
							// await new Promise((resolve) => setTimeout(resolve, 1000));
						} else {
							// error handling
							if (error.response.data.err === 'timeout') {
								showToastOnce('error', t('toasts.error.timeout'), 'timeout');
							} else if (error.response?.status === 401) {
								return {
									error: {
										status: error.response.status,
										error: error.message,
									},
								};
							} else if (error.response.status === 403) {
								if (error.response.data.err === 'Forbidden') {
									showToastOnce(
										'error',
										t('toasts.error.forbidden'),
										'forbidden'
									);
								} else {
									showToastOnce(
										'error',
										error.response.data.err,
										error.response.data.err
									);
								}
							} else {
								showToastOnce(
									'error',
									error.response.data.err,
									error.response.data.err
								);
							}
							// stop the cycle after all errors
							break;
						}
					}
				}

				/* // if we got max attempts
				if (attempt > maxRetries) {
					return {
						error: {
							status: 'CUSTOM_ERROR',
							error: 'Attempts are out',
						},
					};
				} */

				// returns the value if the error is unknown
				return {
					error: {
						status: 'CUSTOM_ERROR',
						error: 'Uknown error',
					},
				};
			},
			providesTags: () => ['Float' as never],
		}),
	}),
	overrideExisting: false,
});
