import React from 'react';

import { useTranslation } from 'react-i18next';

import hieroglyph from '../../../../../assets/icons/hieroglyphIcon.svg';
import changeLanguage from '../../../../../utils/changeLanguage';
import { IDropdownItem } from '../../../../../utils/types';
import Clock from '../../../Clock/Clock';
import DropdownComponent from '../../../Dropdown/Dropdown';
import { flagIcons } from '../../consts';
import './styles.scss';

const AuthorizationNavbar = () => {
	const { t, i18n } = useTranslation();

	const languages: IDropdownItem[] = Array.isArray(i18n.options.supportedLngs)
		? i18n.options.supportedLngs
				.filter((language: string) => language !== 'cimode')
				.map((language: string) => ({
					icon: flagIcons[language],
					iconAlt: `${language}FlagIcon`,
					title: t(`navbar.languages.${language}`),
					action: () => changeLanguage(language),
				}))
		: [];

	return (
		<>
			<h1>{t('navbar.titles.authorization')}</h1>
			<div className='timeLanguage'>
				<Clock />
				<DropdownComponent
					className='languagesDropdown'
					icon={hieroglyph}
					iconAlt='hieroglyphIcon'
					title={t('navbar.languages.title')}
					items={languages}
				/>
			</div>
		</>
	);
};

export default AuthorizationNavbar;
