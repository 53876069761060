import React, { useEffect, useState } from 'react';

import dayjs from 'dayjs';

import { timezones } from '../../../pages/History/components/FilterDateForm/consts';
import './styles.scss';

const Clock = () => {
	const [time, setTime] = useState(dayjs());
	const localTimezoneOffset = dayjs().format('Z');

	useEffect(() => {
		const intervalId = setInterval(() => {
			setTime(dayjs());
		}, 1000);

		return () => clearInterval(intervalId);
	}, []);

	return (
		<div className='clock'>
			<p className='time'>{time.format('HH:mm:ss')}</p>
			<p>
				(
				{
					timezones.find((timezone) => timezone.value === localTimezoneOffset)
						?.title
				}
				)
			</p>
		</div>
	);
};

export default Clock;
