import React, { useEffect, useRef } from 'react';

import { Dropdown } from 'react-bootstrap';

import arrow from '../../../assets/icons/dropdownArrowIcon.svg';
import { IDropdown } from '../../../utils/types';
import './styles.scss';

const DropdownComponent: React.FC<IDropdown> = ({
	className,
	icon,
	iconAlt,
	title,
	items,
	element,
	style,
	show,
	setShow,
}) => {
	const dropdownRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (
				dropdownRef.current &&
				!dropdownRef.current.contains(event.target as Node)
			) {
				setShow && setShow(false);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [setShow]);

	return (
		<Dropdown
			className={className}
			autoClose={className === 'sortAndFilterDropdown' ? 'outside' : true}
			{...(className === 'datepickerDropdown'
				? { show: show ?? false, ref: dropdownRef }
				: {})}>
			<Dropdown.Toggle
				id={className}
				disabled={items && items.length <= 1}
				style={style}
				{...(className === 'datepickerDropdown'
					? { onClick: (_e) => setShow && setShow(!show) }
					: {})}>
				{icon !== '' && (
					<img src={icon} alt={iconAlt} className='dropdownIcon' />
				)}
				{title}
				<img src={arrow} alt='dropdownAlt' className='dropdownArrow' />
			</Dropdown.Toggle>
			<Dropdown.Menu rootCloseEvent='click'>
				{items &&
					items.map(({ icon, iconAlt, title, action }, index) => (
						<Dropdown.Item key={index} onClick={action}>
							{icon && <img src={icon} alt={iconAlt} />}
							{title}
						</Dropdown.Item>
					))}
				{element && <>{element}</>}
			</Dropdown.Menu>
		</Dropdown>
	);
};

export default DropdownComponent;
