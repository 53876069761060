import React, { useState } from 'react';

import { useFormik } from 'formik';
import { ButtonGroup, Form, Modal } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { LineWave } from 'react-loader-spinner';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import * as yup from 'yup';

import { cashManageNums, quickNumbers } from './consts';
import backBtnIcon from '../../../../assets/icons/backBtnIcon.svg';
import closeIcon from '../../../../assets/icons/closeIcon.svg';
import depositIcon from '../../../../assets/icons/depositIcon.svg';
import menuIcon from '../../../../assets/icons/menuIcon.svg';
import withdrawIcon from '../../../../assets/icons/withdrawIcon.svg';
import ButtonComponent from '../../../../components/Common/Button/ButtonComponent';
import MobileMenuModal from '../../../../components/Common/Navbar/components/CashierNavbar/components/MobileMenuModal/MobileMenuModal';
// import { useApi } from '../../../../contexts/ApiContext';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { baseAPI } from '../../../../services/baseAPI';
import { cashierAPI } from '../../../../services/CashierService';
import {
	DomainCashierBalances,
	TransportModBalanceRequest,
} from '../../../../shared/api/Api';
import colorOnPlace from '../../../../utils/colorOnPlace';
import showToastOnce from '../../../../utils/showToastOnce';
import { IModalProps } from '../../../../utils/types';
import Numpad from '../Numpad/Numpad';
import './styles.scss';

const validationSchema: yup.ObjectSchema<
	{ request: TransportModBalanceRequest } & {
		deviceId: string;
		placeId: number;
	}
> = yup.object().shape({
	deviceId: yup.string().required(),
	placeId: yup.number().required(),
	request: yup.object().shape({
		delta: yup
			.object()
			.shape({
				entires: yup.number().optional(),
				final_bonus: yup.number().optional(),
				winnings: yup.number().optional(),
			})
			.optional(),
		trx: yup.string().optional(),
	}),
});

const CashManageModal: React.FC<
	IModalProps & {
		deviceId: string;
		placeId: number;
		delta: DomainCashierBalances;
		setCurrentDetailsUndefined: () => void;
		refetchDetails: () => void;
	}
> = ({
	show,
	handler,
	deviceId,
	placeId,
	delta,
	setCurrentDetailsUndefined,
	refetchDetails,
}) => {
	const { t } = useTranslation();

	const { user } = useAppSelector((state) => state.userReducer);

	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const isDesktop = useMediaQuery({ query: '(min-width: 1351px)' });
	const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1350 });

	const [modifyCash, { isLoading }] = cashierAPI.useModifyCashMutation();
	const { data: float } = cashierAPI.useFetchFloatQuery(user?.id ?? '');

	const formik = useFormik<{
		deviceId: string;
		placeId: number;
		delta: DomainCashierBalances;
		trx: string;
		amount: string;
	}>({
		initialValues: {
			deviceId: deviceId,
			placeId: placeId,
			delta: delta,
			trx: '',
			amount: '',
		},
		validationSchema,
		onSubmit: async (values) => {
			// creating a copy with updated values
			const newDelta = {
				...values.delta,
				entries:
					operationType === 'deposit'
						? Math.round(Number(values.amount) * 100)
						: operationType === 'withdraw'
						? Math.round(-Number(values.amount) * 100)
						: -values.amount,
			};

			try {
				await modifyCash({
					deviceId: deviceId,
					placeId,
					request: {
						delta: newDelta,
						trx: uuidv4(),
					},
					operationType,
				}).unwrap();
				// setCurrentDetailsUndefined();
				handleClose();
				setOperationType('');
			} catch (error: any) {
				if (error.status === 401) {
					navigate('/authorization');
					showToastOnce(
						'error',
						t('toasts.error.cookieExpired'),
						'cookieExpired'
					);
					return () => {
						dispatch(baseAPI.util.resetApiState());
					};
				} else if (error.status === 409) {
					showToastOnce(
						'error',
						<Trans
							i18nKey='toasts.error.overdraft'
							values={{
								balance: String(((float?.balance ?? 0) / 100).toFixed(2)),
								overdraft: String(((float?.overdraft ?? 0) / 100).toFixed(2)),
							}}
							components={{ br: <br /> }}
						/>,
						'overdraft'
					);
				} else if (error.status === 'not enough money') {
					setCurrentDetailsUndefined();
					handleClose();
					showToastOnce('error', t('toasts.error.notEnough'), 'notEnough');
					refetchDetails();
				} else if (error.status === 'negative balance') {
					setCurrentDetailsUndefined();
					handleClose();
					showToastOnce('error', t('toasts.error.notEnough'), 'notEnough');
					refetchDetails();
				} else if (error.status.err === 'min transaction amount exceeded') {
					showToastOnce(
						'error',
						<Trans
							i18nKey='toasts.error.transactionLimit.min'
							values={{
								min: (error.status.data.min_amount / 100).toFixed(2),
							}}
							components={{ br: <br /> }}
						/>,
						'transactionLimit'
					);
					formik.resetForm();
				} else if (error.status.err === 'max transaction amount exceeded') {
					showToastOnce(
						'error',
						<Trans
							i18nKey='toasts.error.transactionLimit.max'
							values={{
								max: (error.status.data.max_amount / 100).toFixed(2),
							}}
							components={{ br: <br /> }}
						/>,
						'transactionLimit'
					);
					formik.resetForm();
				}
			}
		},
	});

	const [operationType, setOperationType] = useState<string>('');

	const [operationTab, setOperationTab] = useState<string>('deposit');

	const handleClose = () => {
		formik.resetForm();
		setOperationType('');
		setOperationTab('deposit');
		handler();
	};

	const [showMobileMenu, setShowMobileMenu] = useState<boolean>(false);
	const handleMobileMenu = () => setShowMobileMenu(!showMobileMenu);

	return (
		<>
			{!isDesktop && !isTablet && (
				<MobileMenuModal
					show={showMobileMenu}
					handler={isLoading ? () => undefined : handleMobileMenu}
				/>
			)}
			<Modal
				show={show}
				onHide={isLoading ? () => undefined : handleClose}
				centered
				className={`cashManageModal ${
					!isDesktop && !isTablet && 'mobileManage'
				}`}>
				{!isDesktop && !isTablet && (
					<Modal.Header>
						<div className='titleAndBackBtn'>
							<ButtonComponent
								icon={backBtnIcon}
								iconAlt='backBtnIcon'
								action={isLoading ? () => undefined : handleClose}
								color='white'
								className='backBtn'
							/>
							<h1>{t('modals.cashManage.manage')}</h1>
						</div>
						<ButtonComponent
							icon={menuIcon}
							iconAlt='menuIcon'
							action={handleMobileMenu}
							className='menuBtn'
							color='white'
						/>
					</Modal.Header>
				)}
				<Modal.Body>
					{(operationType === 'withdraw' || operationType === 'cashOut') &&
					(delta.final_bonus ?? 0) > 0 ? (
						<Form className='approvementBlock' onSubmit={formik.handleSubmit}>
							<div className='texts'>
								<h3>{t(`modals.cashManage.btns.${operationType}`)}</h3>
								<p className='question'>
									{t('modals.cashManage.sure')}{' '}
									{t(`modals.cashManage.btns.${operationType}`).toLowerCase()}{' '}
									<span>${Number(formik.values.amount).toFixed(2)}</span>{' '}
									{t('modals.cashManage.for')}{' '}
									<span>
										{t('modals.cashManage.player').toLowerCase()}
										{placeId}
									</span>
									?
								</p>
								{(delta.final_bonus ?? 0) > 0 && (
									<p className='burn'>
										<span>${(delta.final_bonus ?? 0).toFixed(2)}</span>{' '}
										{t('modals.cashManage.burn')}
									</p>
								)}
							</div>
							<div className='btns'>
								<ButtonComponent
									title={t('modals.cashManage.btns.cancel')}
									className='cancelBtn'
									color='white'
									action={() => setOperationType('')}
								/>
								<ButtonComponent
									title={t(`modals.cashManage.btns.${operationType}`)}
									className={`${operationType}Btn`}
									color='red'
									type='submit'
								/>
							</div>
						</Form>
					) : isLoading ? (
						<LineWave
							height='100'
							width='100'
							ariaLabel='line-wave-loading'
							wrapperClass='lineWave'
							firstLineColor='#EB2F2F'
							middleLineColor='#3BBB26'
							lastLineColor='#3262DE'
						/>
					) : (
						<Form onSubmit={formik.handleSubmit}>
							{(isDesktop || isTablet) && (
								<div className='infoColumn'>
									<div className='placeInfo'>
										<div className='title'>
											<h1>
												{t('modals.cashManage.player')}
												{placeId}
											</h1>
											<div className='colorWrapper'>
												<p>{t('modals.cashManage.color')}:</p>
												<div
													className={`color ${colorOnPlace(
														placeId as 1 | 2 | 3 | 4
													)}`}
												/>
											</div>
										</div>
										<div className='balancesBlocks'>
											<div className='balance'>
												<p>{t('modals.cashManage.balance')}</p>
												<h1
													style={
														((delta.entries ?? 0) / 100).toFixed(2).length >= 12
															? { fontSize: '36px' }
															: {}
													}>
													{((delta.entries ?? 0) / 100).toFixed(2)}
												</h1>
											</div>
											<div className='finalBonusCashOut'>
												{(delta.final_bonus ?? 0) > 0 && (
													<div className='finalBonus'>
														<p>{t('modals.cashManage.finalBonus')}</p>
														<h3>
															{((delta.final_bonus ?? 0) / 100).toFixed(2)}
														</h3>
													</div>
												)}
												<ButtonComponent
													title={t('modals.cashManage.btns.cashOut')}
													className='cashOutBtn'
													color='red'
													action={() => {
														formik.setFieldValue(
															'amount',
															String(delta.entries ?? 0)
														);
														setOperationType('cashOut');
													}}
													type='submit'
													disabled={delta.entries === 0}
												/>
											</div>
										</div>
									</div>
									<div className='quickNumbers'>
										<h3>{t('modals.cashManage.quickNumbers')}</h3>
										<ButtonGroup aria-label='operationTabToggler'>
											<ButtonComponent
												title={t('modals.cashManage.btns.deposit')}
												className='depositToggle'
												color={`${
													operationTab === 'deposit' ? 'green' : 'white'
												}`}
												action={() => setOperationTab('deposit')}
											/>
											<ButtonComponent
												title={t('modals.cashManage.btns.withdraw')}
												className='withdrawToggle'
												color={`${
													operationTab === 'withdraw' ? 'red' : 'white'
												}`}
												action={() => setOperationTab('withdraw')}
												disabled={(delta.entries ?? 0) / 100 < 10}
											/>
										</ButtonGroup>
										<div className='btns'>
											{quickNumbers.map((number) => (
												<ButtonComponent
													key={number}
													title={`${
														operationTab === 'deposit' ? '+' : '-'
													}${number}`}
													className={`quickNumberBtn ${operationTab}`}
													color='white'
													action={() => {
														formik.setFieldValue('amount', Number(number));
														setOperationType(operationTab);
													}}
													disabled={
														operationTab === 'withdraw' &&
														(delta.entries ?? 0) / 100 < Number(number)
													}
													type='submit'
												/>
											))}
										</div>
									</div>
								</div>
							)}
							<div className='numpadWrapper'>
								{!isDesktop && !isTablet && (
									<div className='placeInfo'>
										<div className='title'>
											<div className='colorPlayer'>
												<div
													className={`color ${colorOnPlace(
														placeId as 1 | 2 | 3 | 4
													)}`}>
													{placeId}
												</div>
												<h1>
													{t('modals.cashManage.player')}
													{placeId}
												</h1>
											</div>
											<ButtonComponent
												title={t('modals.cashManage.btns.cashOut')}
												className='cashOutBtn'
												color='red'
												action={() => {
													formik.setFieldValue(
														'amount',
														String(delta.entries ?? 0)
													);
													setOperationType('cashOut');
												}}
												type='submit'
												disabled={delta.entries === 0}
											/>
										</div>
										<div className='balancesBlocks'>
											<div className='balance'>
												<p>{t('modals.cashManage.balance')}</p>
												<h1>{((delta.entries ?? 0) / 100).toFixed(2)}</h1>
											</div>
											<div className='finalBonusCashOut'>
												{(delta.final_bonus ?? 0) > 0 && (
													<div className='finalBonus'>
														<p>{t('modals.cashManage.finalBonus')}</p>
														<h3>
															{((delta.final_bonus ?? 0) / 100).toFixed(2)}
														</h3>
													</div>
												)}
											</div>
										</div>
									</div>
								)}
								<Numpad
									name='amount'
									translationRoute='modals.cashManage'
									formik={formik}
									numbers={cashManageNums}
									// extraInputSign='$'
									maxLength={8}
								/>
								<div className='btns'>
									<ButtonComponent
										title={t('modals.cashManage.btns.deposit')}
										icon={!isDesktop && !isTablet ? depositIcon : ''}
										iconAlt='depositIcon'
										className='depositBtn'
										color='green'
										action={() => setOperationType('deposit')}
										type='submit'
										disabled={Number(formik.values.amount) === 0}
									/>
									<ButtonComponent
										title={t('modals.cashManage.btns.withdraw')}
										icon={!isDesktop && !isTablet ? withdrawIcon : ''}
										iconAlt='withdrawIcon'
										className='withdrawBtn'
										color='red'
										action={() => setOperationType('withdraw')}
										disabled={
											Number(formik.values.amount) * 100 >
												(delta.entries ?? 0) ||
											Number(formik.values.amount) === 0
										}
										type='submit'
									/>
								</div>
							</div>
							{(isDesktop || isTablet) && (
								<ButtonComponent
									color='white'
									className='closeBtn'
									icon={closeIcon}
									iconAlt='closeIcon'
									action={handleClose}
								/>
							)}
						</Form>
					)}
				</Modal.Body>
			</Modal>
		</>
	);
};

export default CashManageModal;
