import enFlag from '../../../assets/icons/flags/en.svg';
import esFlag from '../../../assets/icons/flags/es.svg';
import ptFlag from '../../../assets/icons/flags/pt.svg';

const flagIcons: { [key: string]: string } = {
	en: enFlag,
	es: esFlag,
	pt: ptFlag,
};

const gamePlatforms = [
	'Game Platform 1',
	/* 'Game Platform 1',
	'Game Platform 1',
	'Game Platform 1',
	'Game Platform 1',
	'Game Platform 1', */
];

export { flagIcons, gamePlatforms };
