const base64ToHex = (base64: string): string => {
	// Decodes Base64 to binary array
	const binary = atob(base64);

	// Converts binary array to HEX
	const hex = Array.from(binary)
		.map((char) => char.charCodeAt(0).toString(16).padStart(2, '0'))
		.join('');

	if (hex.length > 32) {
		return hex.slice(0, 32);
	} else if (hex.length < 32) {
		return hex.padEnd(32, '0');
	}

	return hex;
};
export default base64ToHex;
