import React, { useEffect, useState } from 'react';

import { useFormik } from 'formik';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import eye from '../../assets/icons/eyeIcon.svg';
import eyeSlash from '../../assets/icons/eyeSlashIcon.svg';
import personIcon from '../../assets/icons/personIcon.svg';
import ButtonComponent from '../../components/Common/Button/ButtonComponent';
import InputWrapper from '../../components/Common/InputWrapper/InputWrapper';
import NavbarComponent from '../../components/Common/Navbar/Navbar';
import { useAppDispatch } from '../../hooks/redux';
import { baseAPI } from '../../services/baseAPI';
import { userAPI } from '../../services/UserService';
import { TransportUserSignInRequest } from '../../shared/api/Api';
import './styles.scss';

const Authorization = () => {
	const dispatch = useAppDispatch();
	const [signIn] = userAPI.useSignInMutation();
	const navigate = useNavigate();
	const { t } = useTranslation();

	const isDesktop = useMediaQuery({ query: '(min-width: 1351px)' });
	const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1350 });

	const validationSchema: yup.ObjectSchema<TransportUserSignInRequest> = yup
		.object()
		.shape({
			login: yup
				.string()
				.trim()
				.required(t('authorization.errors.login.required'))
				.max(64, t('authorization.errors.login.maxLength'))
				.min(3, t('authorization.errors.login.minLength')),
			password: yup
				.string()
				.trim()
				.required(t('authorization.errors.password.required'))
				.min(4, t('authorization.errors.password.minLength'))
				.max(64, t('authorization.errors.password.maxLength')),
			remember: yup.boolean(),
		});

	const formik = useFormik({
		initialValues: {
			login: localStorage.getItem('rememberedLogin') || '',
			password: '',
			remember: false,
		},
		validationSchema,
		onSubmit: async (values: TransportUserSignInRequest) => {
			dispatch(baseAPI.util.resetApiState());
			try {
				const response = await signIn(values);
				formik.values.remember === true
					? localStorage.setItem('rememberedLogin', formik.values.login)
					: localStorage.removeItem('rememberedLogin');
				if (response.data?.id) navigate('/devices');
			} catch (err: any) {
				// Error is already catched signIn, so we need no actions here
			}
		},
	});

	const [showPassword, setShowPassword] = useState(false);

	const handleEyeClick = () => {
		setShowPassword(!showPassword);
	};

	useEffect(() => {
		const checkJivoAPI = setInterval(() => {
			//@ts-ignore
			if (window.jivo_api) {
				//@ts-ignore
				jivo_destroy();
				clearInterval(checkJivoAPI);
			}
		}, 100);

		// stop checking in 10 sec
		const timeout = setTimeout(() => clearInterval(checkJivoAPI), 10000);

		return () => {
			clearInterval(checkJivoAPI);
			clearTimeout(timeout);
		};
	}, []);

	return (
		<>
			<div className='authorizationBG' />
			{(isDesktop || isTablet) && <NavbarComponent />}
			<main>
				<div className='authorizationPage serviceBlock'>
					<div className='loginBlock'>
						<Form onSubmit={formik.handleSubmit} className='authorizationForm'>
							<InputWrapper
								placeholderRoute='authorization'
								name='login'
								icon={personIcon}
								iconAlt='personIcon'
								formik={formik}
							/>
							<InputWrapper
								name='password'
								placeholderRoute='authorization'
								icon={showPassword ? eye : eyeSlash}
								iconAlt='showPasswordIcon'
								formik={formik}
								type={showPassword ? 'text' : 'password'}
								action={handleEyeClick}
							/>
							<div className='rememberMeCheckbox'>
								<input
									className='form-check-input'
									type='checkbox'
									id='rememberMeCheck'
									checked={formik.values.remember}
									onChange={(e) =>
										formik.setFieldValue('remember', e.target.checked)
									}
								/>
								<label htmlFor='rememberMeCheck' className='form-check-label'>
									{t('authorization.rememberMeCheck')}
								</label>
							</div>
							<ButtonComponent
								className='loginBtn'
								color='blue'
								title={t('authorization.loginBtn')}
								type='submit'
							/>
						</Form>
						{/* <div className='helpLinks'>
							<a href='#'>{t('authorization.helpLinks.forgotPassword')}</a>
							<p className='techSupport'>
								{t('authorization.helpLinks.services.title')}
							</p>
							<p>
								{t('authorization.helpLinks.services.telegram')}:{' '}
								<a href='#'>***</a>
							</p>
						</div> */}
					</div>
				</div>
			</main>
		</>
	);
};

export default Authorization;
