import React, { useState } from 'react';

import dayjs from 'dayjs';
import { useFormik } from 'formik';
import { Modal, Form } from 'react-bootstrap';
import { DtPicker } from 'react-calendar-datetime-picker';
import { IDay } from 'react-calendar-datetime-picker/dist/types/type';
import { useTranslation } from 'react-i18next';
import { LineWave } from 'react-loader-spinner';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import TimePicker from 'react-time-picker';
import * as yup from 'yup';

import incomeIcon from '../../../../../../../assets/icons/depositIcon.svg';
import outcomeIcon from '../../../../../../../assets/icons/withdrawIcon.svg';
import {
	useAppDispatch,
	useAppSelector,
} from '../../../../../../../hooks/redux';
import { timezones } from '../../../../../../../pages/History/components/FilterDateForm/consts';
import { baseAPI } from '../../../../../../../services/baseAPI';
import { cashierAPI } from '../../../../../../../services/CashierService';
import { DomainCashierDevice } from '../../../../../../../shared/api/Api';
import reverseUtc from '../../../../../../../utils/reverseUtc';
import showToastOnce from '../../../../../../../utils/showToastOnce';
import { IModalProps } from '../../../../../../../utils/types';
import { store } from '../../../../../../App/App';
import ButtonComponent from '../../../../../Button/ButtonComponent';
import DropdownComponent from '../../../../../Dropdown/Dropdown';
import './styles.scss';

const StatisticModal: React.FC<IModalProps> = ({ show, handler }) => {
	const { user } = useAppSelector((state) => state.userReducer);
	const { t } = useTranslation();
	const navigate = useNavigate();
	const isMobile = useMediaQuery({ maxWidth: 767 });

	const dispatch = useAppDispatch();

	const state = store.getState();

	const devices = state.baseAPI.queries[`fetchDevices("${user?.id ?? ''}")`]
		?.data as DomainCashierDevice[];

	const device_ids = devices && devices.map((device) => device.id ?? '');

	const [fetchStatistic, { data: statistic, isFetching }] =
		cashierAPI.useLazyFetchDeviceReportQuery();

	const [showStatistic, setShowStatistic] = useState<boolean>(false);

	const localTimezoneOffset = dayjs().format('Z');
	const [selectedUtc, setSelectedUtc] = useState<string>(localTimezoneOffset);

	const validationSchema = yup.object().shape({
		device_ids: yup.array().of(yup.string()).required(),
		fromDate: yup.string().trim().required(),
		fromTime: yup.string().trim().required(),
		toDate: yup.string().trim().required(),
		toTime: yup.string().trim().required(),
	});

	const formik = useFormik({
		initialValues: {
			device_ids: device_ids ?? [],
			fromDate: '',
			fromTime: '00:00:00',
			toDate: '',
			toTime: '23:59:59',
		},
		validationSchema,
		enableReinitialize: true,
		onSubmit: async (values) => {
			if (formik.values.device_ids.length === 0) {
				showToastOnce('error', t('toasts.error.noDevices'), 'noDevices');
				return;
			}

			const formattedValues = {
				device_ids,
				from: dayjs(values.fromDate)
					.hour(parseInt(values.fromTime.split(':')[0], 10))
					.minute(parseInt(values.fromTime.split(':')[1], 10))
					.second(parseInt(values.fromTime.split(':')[2], 10))
					.utcOffset(reverseUtc(selectedUtc))
					.format('YYYY-MM-DDTHH:mm:ss'),
				to: dayjs(values.toDate)
					.hour(parseInt(values.toTime.split(':')[0], 10))
					.minute(parseInt(values.toTime.split(':')[1], 10))
					.second(parseInt(values.toTime.split(':')[2], 10))
					.utcOffset(reverseUtc(selectedUtc))
					.format('YYYY-MM-DDTHH:mm:ss'),
			};

			try {
				await fetchStatistic(formattedValues);
				setShowStatistic(true);
			} catch (error: any) {
				if (error.status === 401) {
					navigate('/authorization');
					showToastOnce(
						'error',
						t('toasts.error.cookieExpired'),
						'cookieExpired'
					);
					return () => {
						dispatch(baseAPI.util.resetApiState());
					};
				}
			}
		},
	});

	const currentDate = dayjs();

	const minDate = {
		year: 2024,
		month: 11,
		day: 18,
	};

	const maxDate = {
		year: currentDate.year(),
		month: currentDate.month() + 1,
		day: currentDate.date(),
	};

	const dayStart = {
		year: currentDate.year(),
		month: currentDate.month() + 1,
		day: currentDate.date(),
		hour: 0,
		minute: 0,
		second: 0,
	};

	const dayEnd = {
		year: currentDate.year(),
		month: currentDate.month() + 1,
		day: currentDate.date(),
		hour: 23,
		minute: 59,
		second: 59,
	};

	const handleDateChange = (date: IDay, field: string) => {
		const utcDate = dayjs
			.utc()
			.year(date.year)
			.month(date.month - 1)
			.date(date.day);
		// .hour(date.hour ?? 0)
		// .minute(date.minute ?? 0)
		// .second(field === 'from' ? 0 : 59);

		formik.setFieldValue(field, utcDate);
	};

	const timezonesWithActions = timezones.map((timezone) => ({
		...timezone,
		action: () => setSelectedUtc(timezone.value),
	}));

	const userStatistic = statistic?.filter((stat) => stat.user_idx === user?.id);

	const totalIncome = userStatistic?.reduce((acc, stat) => {
		return acc + (stat.data?.total_in || 0);
	}, 0);

	const totalOutcome = userStatistic?.reduce((acc, stat) => {
		return acc + (stat.data?.total_out || 0);
	}, 0);

	const totalDelta = (totalIncome ?? 0) - (totalOutcome ?? 0);

	const totalOperations = userStatistic?.length ?? 0;

	const handleClose = () => {
		formik.resetForm();
		setShowStatistic(false);
		handler();
	};

	return (
		<Modal
			show={show}
			onHide={handleClose}
			centered
			className={`statisticModal`}>
			<Modal.Body className={`${isMobile ? 'mobile' : ''}`}>
				<Form className='datepicker' onSubmit={formik.handleSubmit}>
					<div className='inputs'>
						<div className='datepickerWrapper'>
							<label>GMT:</label>
							<DropdownComponent
								className='utcDropdown'
								title={
									timezones.find((timezone) => timezone.value === selectedUtc)
										?.title ?? ''
								}
								icon=''
								iconAlt=''
								items={timezonesWithActions}
							/>
						</div>
						<div className='datepickerWrapper'>
							<label>{t('modals.statistic.labels.from')}</label>
							<div className='datetimeBlock'>
								<DtPicker
									onChange={(date) =>
										date
											? handleDateChange(date, 'fromDate')
											: formik.setFieldValue('fromDate', '')
									}
									placeholder={t('modals.statistic.placeholders.selectDate')}
									maxDate={maxDate}
									minDate={minDate}
									initValue={dayStart}
								/>
								<TimePicker
									onChange={(e) => formik.setFieldValue('fromTime', e)}
									value={formik.values.fromTime}
									format='HH:mm:ss'
									maxDetail='second'
									disableClock
									clearIcon={null}
								/>
							</div>
						</div>
						<div className='datepickerWrapper'>
							<label>{t('modals.statistic.labels.to')}</label>
							<div className='datetimeBlock'>
								<DtPicker
									onChange={(date) =>
										date
											? handleDateChange(date, 'toDate')
											: formik.setFieldValue('toDate', '')
									}
									placeholder={t('modals.statistic.placeholders.selectDate')}
									maxDate={maxDate}
									minDate={minDate}
									initValue={dayEnd}
								/>
								<TimePicker
									onChange={(e) => formik.setFieldValue('toTime', e)}
									value={formik.values.toTime}
									format='HH:mm:ss'
									maxDetail='second'
									disableClock
									clearIcon={null}
								/>
							</div>
						</div>
					</div>
					<div className='btns'>
						<ButtonComponent
							title={t('modals.statistic.btns.closeBtn')}
							color='white'
							className='closeBtn'
							action={handleClose}
						/>
						<ButtonComponent
							title={t('modals.statistic.btns.saveBtn')}
							color='green'
							className='saveBtn'
							type='submit'
							disabled={
								formik.values.fromDate === '' ||
								formik.values.toDate === '' ||
								formik.values.fromTime === null ||
								formik.values.toTime === null
							}
						/>
					</div>
				</Form>
				<div className='statistic'>
					{isFetching ? (
						<LineWave
							height='100'
							width='100'
							ariaLabel='line-wave-loading'
							wrapperClass='lineWave'
							firstLineColor='#EB2F2F'
							middleLineColor='#3BBB26'
							lastLineColor='#3262DE'
						/>
					) : userStatistic && showStatistic ? (
						<>
							<div className='delta'>
								<p>{t('modals.statistic.totalDelta')}</p>
								<h3
									className={`${
										totalDelta > 0 ? 'green' : totalDelta === 0 ? '' : 'red'
									}`}>
									{totalDelta > 0
										? `+${(totalDelta / 100).toFixed(2)}`
										: (totalDelta / 100).toFixed(2)}
								</h3>
							</div>
							<div className='operations'>
								<p>{t('modals.statistic.totalOperations')}</p>
								<h3>{totalOperations}</h3>
							</div>
							<div className='income'>
								<img src={incomeIcon} alt='incomeIcon' />
								<div className='texts'>
									<p>{t('modals.statistic.totalIncome')}</p>
									<h3>{`+${((totalIncome ?? 0) / 100).toFixed(2)}`}</h3>
								</div>
							</div>
							<div className='outcome'>
								<img src={outcomeIcon} alt='outcomeIcon' />
								<div className='texts'>
									<p>{t('modals.statistic.totalOutcome')}</p>
									<h3>{-((totalOutcome ?? 0) / 100).toFixed(2)}</h3>
								</div>
							</div>
						</>
					) : (
						<h3 className='noStatistic'>{t('modals.statistic.select')}</h3>
					)}
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default StatisticModal;
