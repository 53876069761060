import React, { useState } from 'react';

import { useFormik } from 'formik';
import { Form, Modal } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { LineWave } from 'react-loader-spinner';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import copyIcon from '../../../../assets/icons/copyIcon.svg';
import ButtonComponent from '../../../../components/Common/Button/ButtonComponent';
import InputWrapper from '../../../../components/Common/InputWrapper/InputWrapper';
import { useAppDispatch } from '../../../../hooks/redux';
import { baseAPI } from '../../../../services/baseAPI';
import { cashierAPI } from '../../../../services/CashierService';
import { TransportUpdateDeviceRequest } from '../../../../shared/api/Api';
import base64ToHex from '../../../../utils/base64ToHex';
import handleCopyToClipboard from '../../../../utils/handleCopyToClipboard';
import showToastOnce from '../../../../utils/showToastOnce';
import { IModalProps } from '../../../../utils/types';
import './styles.scss';

const validationSchema: yup.ObjectSchema<
	Omit<TransportUpdateDeviceRequest, 'active'>
> = yup.object().shape({
	name: yup.string(),
	hidden: yup.boolean(),
});

const EditDeviceModal: React.FC<
	IModalProps & {
		deviceId: string;
		hidden: boolean;
		name: string;
		uuid: number[];
		gameId: string;
	}
> = ({ show, handler, deviceId, hidden, name, uuid, gameId }) => {
	const { t } = useTranslation();

	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const isDesktop = useMediaQuery({ query: '(min-width: 1351px)' });
	const isMobile = useMediaQuery({ maxWidth: 767 });

	const [addingState, setAddingState] = useState<string>('');

	const [changeDevice, { isLoading }] = cashierAPI.useEditDeviceMutation();

	const formik = useFormik<Omit<TransportUpdateDeviceRequest, 'active'>>({
		initialValues: {
			name: name,
			hidden: hidden,
		},
		validationSchema,
		enableReinitialize: true,
		onSubmit: async (values) => {
			try {
				await changeDevice({ deviceId, request: values }).unwrap();
				handleClose();
			} catch (error: any) {
				if (error.status === 401) {
					navigate('/authorization');
					showToastOnce(
						'error',
						t('toasts.error.cookieExpired'),
						'cookieExpired'
					);
					return () => {
						dispatch(baseAPI.util.resetApiState());
					};
				}
			}
		},
	});

	const handleClose = () => {
		formik.resetForm();
		setAddingState('');
		handler();
	};

	const formattedName =
		name.length > 18 && isMobile ? name.slice(0, 12) + '...' : name;

	const formattedUuid = base64ToHex(String(uuid));

	const renderLoading = () => (
		<LineWave
			height='100'
			width='100'
			ariaLabel='line-wave-loading'
			wrapperClass='lineWave'
			firstLineColor='#EB2F2F'
			middleLineColor='#3BBB26'
			lastLineColor='#3262DE'
		/>
	);

	const renderApprovalBlock = () => (
		<div className='approvementBlock'>
			<h2>{t('modals.editDevice.approvementBlock.title')}</h2>
			<p>
				<Trans
					i18nKey='modals.editDevice.approvementBlock.description'
					values={{ string: formik.values.name }}
					components={{ span: <span /> }}
				/>
			</p>
			<Form onSubmit={formik.handleSubmit}>
				<ButtonComponent
					title={t('modals.editDevice.approvementBlock.btns.back')}
					className='backBtn'
					color='white'
					action={() => setAddingState('')}
				/>
				<ButtonComponent
					title={t('modals.editDevice.approvementBlock.btns.save')}
					className='okBtn'
					color='blue'
					type='submit'
				/>
			</Form>
		</div>
	);

	const renderMainForm = () => (
		<div className='mainForm'>
			<h2>
				{t('modals.editDevice.mainForm.device')}{' '}
				{name !== '' ? formattedName : deviceId}
			</h2>
			<div className='id'>
				<p>{t('modals.editDevice.mainForm.id')}</p>
				<p>{deviceId}</p>
			</div>
			<div className={`uuid ${isDesktop ? 'hover' : ''}`}>
				<p>{t('modals.editDevice.mainForm.uuid')}</p>
				<p
					onClick={(e) => {
						handleCopyToClipboard('UUID', formattedUuid);
					}}>
					{formattedUuid} <img src={copyIcon} alt='copyIcon' />
				</p>
			</div>
			<div className='gameId'>
				<p>{t('modals.editDevice.mainForm.gameId')}</p>
				<p>{gameId}</p>
			</div>
			<div className='name'>
				<p>{t('modals.editDevice.mainForm.name')}</p>
				<InputWrapper
					name='name'
					placeholderRoute='modals.editDevice.mainForm'
					icon=''
					iconAlt=''
					formik={formik}
				/>
			</div>
			{/* <div className='position'>
      <p>{t('modals.editDevice.mainForm.position')}</p>
      <p>{position}</p>
      </div> */}
			{
				/* user?.restrictions?.includes('can_delete_device') && */ <Form.Check
					type='switch'
					id='visibilitySwitch'
					label={t('devices.deviceTypeToggler.visible')}
					className='visibilitySwitch'
					checked={!formik.values.hidden}
					onChange={(e) => formik.setFieldValue('hidden', !e.target.checked)}
				/>
			}
			<div className='btns'>
				<ButtonComponent
					title={t('modals.editDevice.mainForm.btns.cancel')}
					className='backBtn'
					color='white'
					action={handleClose}
				/>
				<ButtonComponent
					title={t('modals.editDevice.mainForm.btns.save')}
					className='okBtn'
					color='blue'
					action={() => setAddingState('save')}
				/>
			</div>
		</div>
	);

	return (
		<Modal
			show={show}
			onHide={isLoading ? () => undefined : handleClose}
			centered
			className='editDeviceModal'>
			<Modal.Body>
				{isLoading
					? renderLoading()
					: addingState.length > 0
					? renderApprovalBlock()
					: renderMainForm()}
			</Modal.Body>
		</Modal>
	);
};

export default EditDeviceModal;
