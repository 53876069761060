import React, { useState } from 'react';

import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useTranslation } from 'react-i18next';
// import { useMediaQuery } from 'react-responsive';

import copyIcon from '../../../../assets/icons/copyIcon.svg';
import penIcon from '../../../../assets/icons/penIcon.svg';
import ButtonComponent from '../../../../components/Common/Button/ButtonComponent';
import { useAppSelector } from '../../../../hooks/redux';
import handleCopyToClipboard from '../../../../utils/handleCopyToClipboard';
import { IDeviceButton } from '../../../../utils/types';
import EditDeviceModal from '../EditDeviceModal/EditDeviceModal';
import './styles.scss';

dayjs.extend(utc);
dayjs.extend(timezone);

const DeviceButton: React.FC<IDeviceButton> = ({
	id,
	name,
	uuid,
	gameId,
	// players,
	// payment,
	hidden,
	action,
	currentDevice,
}) => {
	// const isDesktop = useMediaQuery({ query: '(min-width: 1351px)' });
	const { t } = useTranslation();
	const { user } = useAppSelector((state) => state.userReducer);

	const formattedName = name.length >= 17 ? name.slice(0, 12) + '...' : name;

	/* const formattedUuid =
		uuid.slice(0, 4) + '...' + (isDesktop ? uuid.slice(-4) : ''); */

	// const formattedTime = dayjs.utc(payment).local().format('HH:mm');

	const [showEditDeviceModal, setEditDeviceModal] = useState<boolean>(false);
	const handleShowEditDeviceModal = () =>
		setEditDeviceModal(!showEditDeviceModal);

	return (
		<>
			<EditDeviceModal
				show={showEditDeviceModal}
				handler={handleShowEditDeviceModal}
				deviceId={id}
				hidden={hidden}
				name={name}
				uuid={uuid}
				gameId={gameId}
			/>
			<div
				className={`deviceBtn ${currentDevice ? 'active' : ''}`}
				onClick={action}>
				<p
					onClick={(e) => {
						e.preventDefault();
						e.stopPropagation();
						handleCopyToClipboard('ID', id);
					}}>
					{/* {formattedUuid} */} {id}
					<img src={copyIcon} alt='copyIcon' />
				</p>
				<p>
					{name.length <= 0 ? `${t('devices.device')} ${id}` : formattedName}
				</p>
				{/* <p>4</p> */}
				{/* <p>{formattedTime}</p> */}
				{user?.restrictions?.includes('can_edit_device') ? (
					<ButtonComponent
						icon={penIcon}
						iconAlt='penIcon'
						className='editDeviceBtn'
						color='white'
						action={handleShowEditDeviceModal}
					/>
				) : (
					<div className='mockBtn' />
				)}
			</div>
		</>
	);
};

export default DeviceButton;
