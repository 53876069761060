import { t } from 'i18next';

import { baseAPI } from './baseAPI';
import {
	Api,
	TransportWebOK,
	TransportUserProfile,
	TransportUserSignInRequest,
	TransportUserChangerPasswordRequest,
} from '../shared/api/Api';
import showToastOnce from '../utils/showToastOnce';

const api = new Api({
	withCredentials: true,
	baseURL: `/api/v1`,
});

export const userAPI = baseAPI.injectEndpoints({
	endpoints: (build) => ({
		getUser: build.query<TransportUserProfile, string>({
			async queryFn() {
				const maxRetries = 2;
				let attempt = 0;

				while (attempt <= maxRetries) {
					try {
						const response = await api.a.getA();
						return { data: response.data };
					} catch (error: any) {
						if (error.response.status === 500 && attempt < maxRetries) {
							attempt += 1;
							// await new Promise((resolve) => setTimeout(resolve, 1000));
						} else {
							// error handling
							if (error.response.data?.err === 'timeout') {
								showToastOnce('error', t('toasts.error.timeout'), 'timeout');
							} else if (error.response.status === 401) {
								return {
									error: {
										status: error.response.status,
										error: error.message,
									},
								};
							} else if (error.response.status === 403) {
								if (error.response.data.err === 'Forbidden') {
									showToastOnce(
										'error',
										t('toasts.error.forbidden'),
										'forbidden'
									);
								} else {
									showToastOnce(
										'error',
										error.response.data.err,
										error.response.data.err
									);
								}
							} else if (error.response.data.err === 'Internal server error') {
								return {
									error: {
										status: error.response.data.err,
										error: error.response.data.err,
									},
								};
							} else if (error.response.status === 500) {
								return {
									error: {
										status: error.response.status,
										error: error.response.status,
									},
								};
							} else {
								showToastOnce(
									'error',
									error.response.data.err,
									error.response.data.err
								);
							}
							// stop the cycle after all errors
							break;
						}
					}
				}

				/* // if we got max attempts
				if (attempt > maxRetries) {
					return {
						error: {
							status: 'CUSTOM_ERROR',
							error: 'Attempts are out',
						},
					};
				} */

				// returns the value if the error is unknown
				return {
					error: {
						status: 'CUSTOM_ERROR',
						error: 'Uknown error',
					},
				};
			},
			providesTags: () => ['User' as never],
		}),
		signIn: build.mutation<TransportUserProfile, TransportUserSignInRequest>({
			async queryFn(request) {
				const maxRetries = 2;
				let attempt = 0;

				while (attempt <= maxRetries) {
					try {
						const response = await api.signIn.signInCreate(request);
						return { data: response.data };
					} catch (error: any) {
						if (error.response.status === 500 && attempt < maxRetries) {
							attempt += 1;
							// await new Promise((resolve) => setTimeout(resolve, 1000));
						} else {
							// error handling
							if (error.response.data.err === 'timeout') {
								showToastOnce('error', t('toasts.error.timeout'), 'timeout');
							} else if (error.response.status === 403) {
								if (error.response.data.err === 'Forbidden') {
									showToastOnce(
										'error',
										t('toasts.error.forbidden'),
										'forbidden'
									);
								} else if (error.response.data.err === 'user blocked') {
									showToastOnce('error', t('toasts.error.blocked'), 'blocked');
								} else {
									showToastOnce(
										'error',
										error.response.data.err,
										error.response.data.err
									);
								}
							} else if (error.response.data.err === 'Error 1054 (42S22):') {
								showToastOnce(
									'error',
									t('toasts.requestErrors.serverError'),
									'serverError'
								);
							} else if (error.response.data.err === 'user not found') {
								showToastOnce(
									'error',
									t('toasts.requestErrors.userNotFound'),
									'userNotFound'
								);
							} else {
								showToastOnce(
									'error',
									error.response.data.err,
									error.response.data.err
								);
							}
							// stop the cycle after all errors
							break;
						}
					}
				}

				/* // if we got max attempts
				if (attempt > maxRetries) {
					return {
						error: {
							status: 'CUSTOM_ERROR',
							error: 'Attempts are out',
						},
					};
				} */

				// returns the value if the error is unknown
				return {
					error: {
						status: 'CUSTOM_ERROR',
						error: 'Uknown error',
					},
				};
			},
			invalidatesTags: () => ['User' as never],
		}),
		signOut: build.mutation<TransportWebOK, string>({
			async queryFn() {
				const maxRetries = 2;
				let attempt = 0;

				while (attempt <= maxRetries) {
					try {
						const response = await api.a.signOutList();
						return { data: response.data };
					} catch (error: any) {
						if (error.response.status === 500 && attempt < maxRetries) {
							attempt += 1;
							// await new Promise((resolve) => setTimeout(resolve, 1000));
							// error handling
						} else {
							if (error.response.data.err === 'timeout') {
								showToastOnce('error', t('toasts.error.timeout'), 'timeout');
							} else if (error.response.status === 401) {
								return {
									error: {
										status: error.response.status,
										error: error.message,
									},
								};
							} else if (error.response.status === 403) {
								if (error.response.data.err === 'Forbidden') {
									showToastOnce(
										'error',
										t('toasts.error.forbidden'),
										'forbidden'
									);
								} else {
									showToastOnce(
										'error',
										error.response.data.err,
										error.response.data.err
									);
								}
							} else {
								showToastOnce(
									'error',
									error.response.data.err,
									error.response.data.err
								);
							}
							// stop the cycle after all errors
							break;
						}
					}
				}

				/* // if we got max attempts
				if (attempt > maxRetries) {
					return {
						error: {
							status: 'CUSTOM_ERROR',
							error: 'Attempts are out',
						},
					};
				} */

				// returns the value if the error is unknown
				return {
					error: {
						status: 'CUSTOM_ERROR',
						error: 'Uknown error',
					},
				};
			},
			invalidatesTags: () => ['User' as never],
		}),
		changePassword: build.mutation<
			TransportWebOK,
			TransportUserChangerPasswordRequest
		>({
			async queryFn(request) {
				const maxRetries = 2;
				let attempt = 0;

				while (attempt <= maxRetries) {
					try {
						const response = await api.a.changePasswordCreate(request);
						return { data: response.data };
					} catch (error: any) {
						if (error.response.status === 500 && attempt < maxRetries) {
							attempt += 1;
							// await new Promise((resolve) => setTimeout(resolve, 1000));
						} else {
							// error handling
							if (error.response.data.err === 'timeout') {
								showToastOnce('error', t('toasts.error.timeout'), 'timeout');
							} else if (error.response?.status === 401) {
								return {
									error: {
										status: error.response.status,
										error: error.message,
									},
								};
							} else if (error.response.status === 403) {
								if (error.response.data.err === 'Forbidden') {
									showToastOnce(
										'error',
										t('toasts.error.forbidden'),
										'forbidden'
									);
								} else {
									showToastOnce(
										'error',
										error.response.data.err,
										error.response.data.err
									);
								}
							} else {
								showToastOnce(
									'error',
									error.response.data.err,
									error.response.data.err
								);
							}
							// stop the cycle after all errors
							break;
						}
					}
				}

				/* // if we got max attempts
      if (attempt > maxRetries) {
        return {
          error: {
            status: 'CUSTOM_ERROR',
            error: 'Attempts are out',
          },
        };
      } */

				// returns the value if the error is unknown
				return {
					error: {
						status: 'CUSTOM_ERROR',
						error: 'Uknown error',
					},
				};
			},
		}),
	}),
	overrideExisting: false,
});
