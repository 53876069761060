import { toast } from 'react-toastify';

const showToastOnce = (
	type: 'success' | 'error' | 'info' | 'warning',
	message: string | React.ReactElement,
	id: string
) => {
	if (!toast.isActive(id)) {
		toast[type](message, { toastId: id });
	}
};

export default showToastOnce;
