import React from 'react';

import { Button } from 'react-bootstrap';

import { IButton } from '../../../utils/types';
import './styles.scss';

const ButtonComponent: React.FC<IButton> = ({
	type,
	className,
	color,
	icon,
	iconAlt,
	title,
	action,
	disabled,
}) => {
	const preventHandler = (e: any) => {
		e.preventDefault();
		e.stopPropagation();
		action && action();
	};

	return (
		<Button
			className={`buttonComponent ${className} ${color}`}
			type={type ?? 'button'}
			onClick={className === 'editDeviceBtn' ? preventHandler : action}
			disabled={disabled}>
			{icon && icon !== '' && <img src={icon} alt={iconAlt} />}
			{title && title}
		</Button>
	);
};

export default ButtonComponent;
