export const timezones = [
	{ title: 'GMT+0', value: '+00:00' },
	{ title: 'GMT+1', value: '+01:00' },
	{ title: 'GMT+2', value: '+02:00' },
	{ title: 'GMT+3', value: '+03:00' },
	{ title: 'GMT+4', value: '+04:00' },
	{ title: 'GMT+5', value: '+05:00' },
	{ title: 'GMT+6', value: '+06:00' },
	{ title: 'GMT+7', value: '+07:00' },
	{ title: 'GMT+8', value: '+08:00' },
	{ title: 'GMT+9', value: '+09:00' },
	{ title: 'GMT+10', value: '+10:00' },
	{ title: 'GMT+11', value: '+11:00' },
	{ title: 'GMT+12', value: '+12:00' },
	{ title: 'GMT-1', value: '-01:00' },
	{ title: 'GMT-2', value: '-02:00' },
	{ title: 'GMT-3', value: '-03:00' },
	{ title: 'GMT-4', value: '-04:00' },
	{ title: 'GMT-5', value: '-05:00' },
	{ title: 'GMT-6', value: '-06:00' },
	{ title: 'GMT-7', value: '-07:00' },
	{ title: 'GMT-8', value: '-08:00' },
	{ title: 'GMT-9', value: '-09:00' },
	{ title: 'GMT-10', value: '-10:00' },
	{ title: 'GMT-11', value: '-11:00' },
	{ title: 'GMT-12', value: '-12:00' },
];
