import React, { ReactElement, useContext } from 'react';

import dayjs from 'dayjs';
import {
	Accordion,
	AccordionContext,
	useAccordionButton,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import accordionIcon from '../../../../assets/icons/accordionIcon.svg';
import closeIcon from '../../../../assets/icons/closeIcon.svg';
import copyIcon from '../../../../assets/icons/copyIcon.svg';
import depositIcon from '../../../../assets/icons/depositIcon.svg';
import withdrawIcon from '../../../../assets/icons/withdrawIcon.svg';
import { DomainAccountOperationReportResult } from '../../../../shared/api/Api';
import colorOnPlace from '../../../../utils/colorOnPlace';
import handleCopyToClipboard from '../../../../utils/handleCopyToClipboard';
import './styles.scss';

const HistoryTab: React.FC<
	DomainAccountOperationReportResult & { utc: string }
> = ({ device_name, device_id, place, data, trx, created_at, utc }) => {
	const { t } = useTranslation();
	const isDeposit = (data?.total_in ?? 0) > (data?.total_out ?? 0);
	const isDesktop = useMediaQuery({ query: '(min-width: 1351px)' });
	const isMobile = useMediaQuery({ maxWidth: 767 });

	const formattedId = isDesktop
		? trx?.slice(0, 4) + '...' + trx?.slice(-4)
		: trx?.slice(0, 4) + '...';

	const formattedAmount = isDeposit
		? ((data?.total_in ?? 0) / 100).toFixed(2)
		: (-(data?.total_out ?? 0) / 100).toFixed(2);

	const formattedDate = dayjs(created_at).utcOffset(utc).format('DD.MM HH:mm');

	const ContextAwareToggle: React.FC<{
		children: ReactElement | ReactElement[];
		eventKey: string;
		callback?: (eventKey: string) => void;
		className: string;
	}> = ({ children, eventKey, callback, className }) => {
		const { activeEventKey } = useContext(AccordionContext);

		const decoratedOnClick = useAccordionButton(
			eventKey,
			() => callback && callback(eventKey)
		);

		const isCurrentEventKey = activeEventKey === eventKey;

		return (
			<button
				type='button'
				className={`${className} ${isCurrentEventKey ? 'active' : ''}`}
				onClick={decoratedOnClick}>
				{children}
			</button>
		);
	};

	return !isMobile ? (
		<div className='historyTab'>
			<p className='deviceName'>
				{(device_name?.length ?? 0) === 0
					? `${t('devices.device')} ${device_id}`
					: device_name}
			</p>
			<p className='deviceId'>{device_id}</p>
			<div className='player'>
				<div
					className={`color ${colorOnPlace(
						place as 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8
					)}`}>
					{!isDesktop ? place : ''}
				</div>
				{isDesktop && (
					<p>
						{t('devices.detailsTitles.player')} #{place}
					</p>
				)}
			</div>
			<p
				className='transactionId'
				onClick={(e) => {
					e.preventDefault();
					e.stopPropagation();
					handleCopyToClipboard('ID', String(trx));
				}}>
				{formattedId}
				<img src={copyIcon} alt='copyIcon' />
			</p>
			<p
				className={`amount ${isDeposit ? 'deposit' : 'withdraw'}`}
				style={{
					...(!isDesktop && formattedAmount.length >= 11
						? { fontSize: '11px' }
						: {}),
				}}>
				{isDeposit ? `+${formattedAmount}` : formattedAmount}
			</p>
			<div className={`type ${isDeposit ? 'deposit' : 'withdraw'}`}>
				<img
					src={isDeposit ? depositIcon : withdrawIcon}
					alt={isDeposit ? depositIcon : withdrawIcon}
					className='typeIcon'
				/>
				{isDesktop && (
					<p>
						{isDeposit
							? t('modals.cashManage.btns.deposit')
							: t('modals.cashManage.btns.withdraw')}
					</p>
				)}
			</div>
			<p className='date'>{formattedDate}</p>
		</div>
	) : (
		<Accordion.Item
			eventKey={`${device_id}-${created_at}`}
			className='historyTab'>
			<ContextAwareToggle
				eventKey={`${device_id}-${created_at}`}
				className='historyHeader'>
				<p className='deviceName'>
					{(device_name?.length ?? 0) === 0
						? `${t('devices.device')} ${device_id}`
						: device_name}
				</p>
				<div className={`type ${isDeposit ? 'deposit' : 'withdraw'}`}>
					<img
						src={isDeposit ? depositIcon : withdrawIcon}
						alt={isDeposit ? depositIcon : withdrawIcon}
						className='typeIcon'
					/>
				</div>
				<p
					className={`amount ${isDeposit ? 'deposit' : 'withdraw'}`}
					style={{
						...(!isDesktop && formattedAmount.length >= 11
							? { fontSize: '11px' }
							: {}),
					}}>
					{isDeposit ? `+${formattedAmount}` : formattedAmount}
				</p>
				<div className='player'>
					<div
						className={`color ${colorOnPlace(
							place as 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8
						)}`}>
						{place}
					</div>
				</div>
				<p className='date'>{formattedDate}</p>
				<img
					src={accordionIcon}
					alt='accordionIcon'
					className='accordionIcon'
				/>
			</ContextAwareToggle>
			<Accordion.Body className='historyBody'>
				<div className='info'>
					<div className='deviceName string'>
						<p className='title'>{t('history.titles.deviceName')}</p>
						<p className='value'>
							{(device_name?.length ?? 0) === 0
								? `${t('devices.device')} ${device_id}`
								: device_name}
						</p>
					</div>
					<div className='extraInfo'>
						<div className='column'>
							<div className='type string'>
								<p className='title'>{t('history.titles.type')}</p>
								<div className={`value ${isDeposit ? 'deposit' : 'withdraw'}`}>
									<img
										src={isDeposit ? depositIcon : withdrawIcon}
										alt={isDeposit ? depositIcon : withdrawIcon}
										className='typeIcon'
									/>
									<p>
										{isDeposit
											? t('modals.cashManage.btns.deposit')
											: t('modals.cashManage.btns.withdraw')}
									</p>
								</div>
							</div>
							<div className='deviceId string'>
								<p className='title'>{t('history.titles.deviceId')}</p>
								<p className='value'>{device_id}</p>
							</div>
						</div>
						<div className='column'>
							<div className='amount string'>
								<p className='title'>{t('history.titles.amount')}</p>
								<p
									className={`value ${isDeposit ? 'deposit' : 'withdraw'}`}
									style={{
										...(!isDesktop && formattedAmount.length >= 11
											? { fontSize: '11px' }
											: {}),
									}}>
									{isDeposit ? `+${formattedAmount}` : formattedAmount}
								</p>
							</div>
							<div className='transactionId string'>
								<p className='title'>{t('history.titles.transactionId')}</p>
								<p
									className='value'
									onClick={(e) => {
										e.preventDefault();
										e.stopPropagation();
										handleCopyToClipboard('ID', String(trx));
									}}>
									{trx?.slice(0, 4) + '...' + trx?.slice(-4)}
									<img src={copyIcon} alt='copyIcon' />
								</p>
							</div>
						</div>
						<div className='column'>
							<div className='player string'>
								<p className='title'>{t('history.titles.player')}</p>
								<div className='value'>
									<div
										className={`color ${colorOnPlace(
											place as 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8
										)}`}>
										{place}
									</div>
								</div>
							</div>
							<div className='date string'>
								<p className='title'>{t('history.titles.date')}</p>
								<p className='value'>{formattedDate}</p>
							</div>
						</div>
					</div>
				</div>
				<ContextAwareToggle
					eventKey={`${device_id}-${created_at}`}
					className='closeBtn'>
					<img src={closeIcon} alt='closeIcon' />
				</ContextAwareToggle>
			</Accordion.Body>
		</Accordion.Item>
	);
};

export default HistoryTab;
