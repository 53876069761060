import React, { useState } from 'react';

import { useFormik } from 'formik';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import lockIcon from '../../../../assets/icons/lockIcon.svg';
import ButtonComponent from '../../../../components/Common/Button/ButtonComponent';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { baseAPI } from '../../../../services/baseAPI';
import { userAPI } from '../../../../services/UserService';
import { userSlice } from '../../../../store/reducers/UserSlice';
import showToastOnce from '../../../../utils/showToastOnce';
import { addDeviceNums } from '../AddDeviceModal/consts';
import Numpad from '../Numpad/Numpad';
import './styles.scss';

const validationSchema = yup.object().shape({
	pin: yup.string(),
	repeatPin: yup.string(),
});

const LockModal = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const { user } = useAppSelector((state) => state.userReducer);
	const { deleteUser } = userSlice.actions;
	const [signOut] = userAPI.useSignOutMutation();

	const handleSignOut = async () => {
		try {
			const response = await signOut(user?.id ?? '').unwrap();
			if (response.ok) {
				navigate('/authorization');
				dispatch(deleteUser());
				return () => {
					dispatch(baseAPI.util.resetApiState());
				};
			}
		} catch (error: any) {
			if (error.status === 401) {
				navigate('/authorization');
				showToastOnce(
					'error',
					t('toasts.error.cookieExpired'),
					'cookieExpired'
				);
				return () => {
					dispatch(baseAPI.util.resetApiState());
				};
			}
		}
	};

	const isDesktop = useMediaQuery({ query: '(min-width: 1351px)' });
	const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1350 });

	const formik = useFormik({
		initialValues: {
			pin: '',
			repeatPin: '',
		},
		validationSchema,
		onSubmit: async (values) => {
			//
		},
	});

	const [correctPin, setCorrectPin] = useState<boolean>(true);

	return (
		<Modal show={false} centered className='lockModal'>
			<Modal.Body
				className={`${isDesktop ? 'desktop' : isTablet ? 'tablet' : 'mobile'}`}>
				<Numpad
					name='pin'
					translationRoute='modals.addDevice'
					formik={formik}
					numbers={addDeviceNums}
					maxLength={5}
				/>
				<div className='lockBlock'>
					<div className='textsBlock'>
						<h1>{t('modals.lock.title')}</h1>
						{(isDesktop || isTablet) && (
							<div className='descriptionBlock'>
								<p>{t('modals.lock.description')}</p>
								<p>{t('modals.lock.forgot')}</p>
							</div>
						)}
						<ButtonComponent
							className='logOutBtn'
							color='white'
							action={handleSignOut}
							title={t('modals.lock.logOut')}
						/>
					</div>
					<img
						src={lockIcon}
						alt='lockIcon'
						className={`lockIcon ${!correctPin ? 'shake' : ''}`}
					/>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default LockModal;
