import React from 'react';

import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import copyIcon from '../../../../assets/icons/copyIcon.svg';
import depositIcon from '../../../../assets/icons/depositIcon.svg';
import withdrawIcon from '../../../../assets/icons/withdrawIcon.svg';
import { DomainAccountOperationReportResult } from '../../../../shared/api/Api';
import colorOnPlace from '../../../../utils/colorOnPlace';
import handleCopyToClipboard from '../../../../utils/handleCopyToClipboard';
import './styles.scss';

const DeviceHistoryTab: React.FC<DomainAccountOperationReportResult> = ({
	created_at,
	// currency,
	place,
	data,
	trx,
}) => {
	const { t } = useTranslation();
	const isDeposit = (data?.total_in ?? 0) > (data?.total_out ?? 0);

	const isDesktop = useMediaQuery({ query: '(min-width: 1351px)' });

	const formattedId = isDesktop
		? trx?.slice(0, 4) + '...' + trx?.slice(-4)
		: trx?.slice(0, 4) + '...';
	const formattedAmount = isDeposit
		? ((data?.total_in ?? 0) / 100).toFixed(2)
		: (-(data?.total_out ?? 0) / 100).toFixed(2);
	const formattedDate = dayjs.utc(created_at).local().format('DD.MM HH:mm');

	return (
		<div className='deviceHistoryTab'>
			<div className={`type ${isDeposit ? 'deposit' : 'withdraw'}`}>
				<img
					src={isDeposit ? depositIcon : withdrawIcon}
					alt={isDeposit ? depositIcon : withdrawIcon}
					className='typeIcon'
				/>
				{isDesktop && (
					<p>
						{isDeposit
							? t('modals.cashManage.btns.deposit')
							: t('modals.cashManage.btns.withdraw')}
					</p>
				)}
			</div>
			<p
				className='transactionId'
				onClick={(e) => {
					e.preventDefault();
					e.stopPropagation();
					handleCopyToClipboard('ID', String(trx));
				}}>
				{formattedId}
				<img src={copyIcon} alt='copyIcon' />
			</p>
			<div className='player'>
				<div
					className={`color ${colorOnPlace(
						place as 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8
					)}`}>
					{!isDesktop ? place : ''}
				</div>
				{isDesktop && (
					<p>
						{t('devices.detailsTitles.player')} #{place}
					</p>
				)}
			</div>
			<p
				className={`amount ${isDeposit ? 'deposit' : 'withdraw'}`}
				style={{
					...(!isDesktop && formattedAmount.length >= 11
						? { fontSize: '11px' }
						: {}),
				}}>
				{isDeposit ? `+${formattedAmount}` : formattedAmount}
			</p>
			<p className='date'>{formattedDate}</p>
		</div>
	);
};

export default DeviceHistoryTab;
