import React, { useState } from 'react';

import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import closeIcon from '../../../../../../../assets/icons/closeIcon.svg';
import devicesIcon from '../../../../../../../assets/icons/devicesIcon.svg';
// import gameController from '../../../../../../../assets/icons/gameControllerIcon.svg';
import historyIcon from '../../../../../../../assets/icons/historyIcon.svg';
import logoutIcon from '../../../../../../../assets/icons/logoutIcon.svg';
import statisticIcon from '../../../../../../../assets/icons/statisticIcon.svg';
import {
	useAppDispatch,
	useAppSelector,
} from '../../../../../../../hooks/redux';
import ChangePasswordModal from '../../../../../../../pages/Devices/components/ChangePasswordModal/ChangePasswordModal';
import { baseAPI } from '../../../../../../../services/baseAPI';
import { userAPI } from '../../../../../../../services/UserService';
import { userSlice } from '../../../../../../../store/reducers/UserSlice';
import showToastOnce from '../../../../../../../utils/showToastOnce';
import { IModalProps } from '../../../../../../../utils/types';
import ButtonComponent from '../../../../../Button/ButtonComponent';
// import DropdownComponent from '../../../../../Dropdown/Dropdown';
// import { gamePlatforms } from '../../../../consts';
import Float from '../../../../../Float/Float';
import StatisticModal from '../StatisticModal/StatisticModal';
import './styles.scss';

const MobileMenuModal: React.FC<
	IModalProps & {
		setGamePlatform?: (data: string) => void;
		gamePlatform?: string;
	}
> = ({ show, handler, setGamePlatform, gamePlatform }) => {
	const { pathname } = useLocation();
	const isActive = (title: string) => pathname.includes(title.toLowerCase());

	const { t } = useTranslation();

	const navigate = useNavigate();

	const dispatch = useAppDispatch();
	const { user } = useAppSelector((state) => state.userReducer);
	const { deleteUser } = userSlice.actions;
	const [signOut] = userAPI.useSignOutMutation();

	const handleSignOut = async () => {
		try {
			const response = await signOut(user?.id ?? '').unwrap();
			if (response.ok) {
				navigate('/authorization');
				dispatch(deleteUser());
				return () => {
					dispatch(baseAPI.util.resetApiState());
				};
			}
		} catch (error: any) {
			if (error.status === 401) {
				navigate('/authorization');
				showToastOnce(
					'error',
					t('toasts.error.cookieExpired'),
					'cookieExpired'
				);
				return () => {
					dispatch(baseAPI.util.resetApiState());
				};
			}
		}
	};

	const isDesktop = useMediaQuery({ query: '(min-width: 1351px)' });
	const isMobile = useMediaQuery({ maxWidth: 767 });

	const [showStatistic, setShowStatistic] = useState<boolean>(false);
	const handleShowStatistic = () => setShowStatistic(!showStatistic);

	const [showChangePasswordModal, setShowChangePasswordModal] =
		useState<boolean>(false);
	const handleChangePasswordModal = () =>
		setShowChangePasswordModal(!showChangePasswordModal);

	return (
		<>
			{!isDesktop && (
				<StatisticModal show={showStatistic} handler={handleShowStatistic} />
			)}
			{!isDesktop && (
				<ChangePasswordModal
					show={showChangePasswordModal}
					handler={handleChangePasswordModal}
				/>
			)}
			<Modal show={show} onHide={handler} className={`mobileMenuModal`}>
				<Modal.Body>
					<ButtonComponent
						icon={closeIcon}
						iconAlt='closeIcon'
						className='closeBtn'
						action={handler}
						color='white'
					/>
					<div className='navigationBtns'>
						{/* {isMobile && (
						<DropdownComponent
							className='gamePlatformDropdown'
							icon={gameController}
							iconAlt='hieroglyphIcon'
							title={`${t('navbar.gamePlatform')} ${gamePlatform}`}
							items={gamePlatforms.map((platform) => ({
								title: platform,
								action: () =>
									setGamePlatform && setGamePlatform(platform.slice(-1)),
							}))}
						/>
					)} */}
						<ButtonComponent
							title={t('navbar.titles.devices')}
							className={`mobileMenuBtn devicesBtn ${
								isActive('devices') ? 'active' : ''
							}`}
							color='white'
							icon={devicesIcon}
							iconAlt='devicesIcon'
							action={() => navigate('/devices')}
						/>
						<ButtonComponent
							title={t('navbar.titles.statistic')}
							className={`mobileMenuBtn statisticBtn ${
								isActive('statistic') ? 'active' : ''
							}`}
							color='white'
							icon={statisticIcon}
							iconAlt='statisticIcon'
							action={handleShowStatistic}
						/>
						{user?.restrictions?.includes('can_view_history_reports') && (
							<ButtonComponent
								title={t('navbar.titles.history')}
								className={`mobileMenuBtn historyBtn ${
									isActive('history') ? 'active' : ''
								}`}
								color='white'
								icon={historyIcon}
								iconAlt='historyIcon'
								action={() => navigate('/history')}
							/>
						)}
						{isMobile && <Float />}
					</div>
					<div className='profileBtns'>
						{isMobile && (
							<Link
								className='profile'
								/* to='/profile' */ to=''
								onClick={handleChangePasswordModal}>
								<p className='name'>
									{(user?.name?.length ?? 0) > 0 ? user?.name : 'Cashier'}
								</p>
								<div className='avatar noAvatar'>
									{(user?.name?.length ?? 0) > 0
										? user?.name?.slice(0, 1).toUpperCase()
										: 'Cashier'.slice(0, 1).toUpperCase()}
								</div>
							</Link>
						)}
						<ButtonComponent
							title={t('navbar.btns.logout')}
							className='mobileMenuBtn logoutBtn'
							color='white'
							icon={logoutIcon}
							iconAlt='logoutIcon'
							action={handleSignOut}
						/>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default MobileMenuModal;
