import { t } from 'i18next';

import showToastOnce from './showToastOnce';

const handleCopyToClipboard = async (name: string, value: string) => {
	try {
		await navigator.clipboard.writeText(value);
		showToastOnce(
			'success',
			t('toasts.success.succeedToCopy', { string: name }),
			'succeedToCopy'
		);
	} catch (err) {
		showToastOnce(
			'error',
			t('toasts.error.failedToCopy', { string: name }),
			'failedToCopy'
		);
	}
};

export default handleCopyToClipboard;
