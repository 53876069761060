/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import { ButtonGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { LineWave } from 'react-loader-spinner';
import { useNavigate, useParams } from 'react-router-dom';

import backBtnIcon from '../../../../assets/icons/backBtnIcon.svg';
import menuIcon from '../../../../assets/icons/menuIcon.svg';
import ButtonComponent from '../../../../components/Common/Button/ButtonComponent';
import MobileMenuModal from '../../../../components/Common/Navbar/components/CashierNavbar/components/MobileMenuModal/MobileMenuModal';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { baseAPI } from '../../../../services/baseAPI';
import { cashierAPI } from '../../../../services/CashierService';
import { TransportCashierBalanceResponse } from '../../../../shared/api/Api';
import showToastOnce from '../../../../utils/showToastOnce';
import DailyStatisticsTab from '../DailyStatisticsTab/DailyStatisticsTab';
import DeviceDetailTab from '../DeviceDetailTab/DeviceDetailTab';
import DeviceHistoryTab from '../DeviceHistoryTab/DeviceHistoryTab';
import './styles.scss';

const DeviceDetailsMobile: React.FC = () => {
	const { t } = useTranslation();
	const { user } = useAppSelector((state) => state.userReducer);
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const { deviceId } = useParams();

	const [currentDeviceTab, setCurrentDeviceTab] = useState<string>('players');

	//dates and HISTORY
	const currentDate = dayjs().format('YYYY-MM-DD');
	const nextDate = dayjs().add(1, 'day').format('YYYY-MM-DD');

	const {
		data: history,
		isFetching: historyFetching,
		error: historyError,
		// refetch,
	} = cashierAPI.useFetchDeviceReportQuery(
		{
			device_ids: [deviceId ?? ''],
			from: currentDate,
			to: nextDate,
		},
		{ skip: currentDeviceTab !== 'history' }
	);

	const userHistory = history?.filter((story) => story.user_idx === user?.id);

	const dailyDeposit = userHistory?.reduce((acc, stat) => {
		return acc + (stat.data?.total_in || 0);
	}, 0);

	const dailyWithdraw = userHistory?.reduce((acc, stat) => {
		return acc + (stat.data?.total_out || 0);
	}, 0);

	// const dailyDelta = (dailyDeposit ?? 0) - (dailyWithdraw ?? 0);

	const {
		data: details,
		error: detailsError,
		refetch: refetchDetails,
	} = cashierAPI.useFetchDeviceDetailQuery(deviceId as string, {
		skip: deviceId === undefined,
		pollingInterval: 5000,
		skipPollingIfUnfocused: false,
	});

	const [currentDetails, setCurrentDetails] = useState<
		TransportCashierBalanceResponse | undefined
	>(undefined);

	const { data: devices, error: devicesError } =
		cashierAPI.useFetchDevicesQuery(user?.id ?? '');

	const currentName = devices?.find((device) => device.id === deviceId)?.name;

	const formattedCurrentName =
		currentName && currentName.length > 18
			? currentName.slice(0, 15) + '...'
			: currentName;

	const [showMobileMenu, setShowMobileMenu] = useState<boolean>(false);
	const handleMobileMenu = () => setShowMobileMenu(!showMobileMenu);

	useEffect(() => {
		if (details) {
			setCurrentDetails(details);
		}
	}, [details]);

	useEffect(() => {
		if (devicesError) {
			//@ts-ignore
			if (devicesError.status === 401) {
				navigate('/authorization');
				showToastOnce(
					'error',
					t('toasts.error.cookieExpired'),
					'cookieExpired'
				);
				return () => {
					dispatch(baseAPI.util.resetApiState());
				};
			}
		}
	}, [devicesError, navigate, t, dispatch]);

	useEffect(() => {
		if (detailsError) {
			//@ts-ignore
			if (detailsError.status === 401) {
				navigate('/authorization');
				showToastOnce(
					'error',
					t('toasts.error.cookieExpired'),
					'cookieExpired'
				);
				return () => {
					dispatch(baseAPI.util.resetApiState());
				};
			}
		}
	}, [detailsError, navigate, t, dispatch]);

	useEffect(() => {
		if (historyError) {
			//@ts-ignore
			if (historyError.status === 401) {
				navigate('/authorization');
				showToastOnce(
					'error',
					t('toasts.error.cookieExpired'),
					'cookieExpired'
				);
				return () => {
					dispatch(baseAPI.util.resetApiState());
				};
			}
		}
	}, [historyError, navigate, t, dispatch]);

	const showLoader =
		!currentDetails && deviceId !== '' && deviceId !== undefined;

	return (
		<>
			<MobileMenuModal show={showMobileMenu} handler={handleMobileMenu} />
			<div className='cashierBG' />
			<header className='deviceDetailsMobileHeader'>
				<div className='titleAndBackBtn'>
					<ButtonComponent
						icon={backBtnIcon}
						iconAlt='backBtnIcon'
						action={() => {
							setCurrentDetails(undefined);
							navigate(-1);
						}}
						color='white'
						className='backBtn'
					/>
					<h1>
						{formattedCurrentName || t('devices.device')}{' '}
						{!currentName && (deviceId || t('devices.notSelected'))}
					</h1>
				</div>
				<ButtonComponent
					icon={menuIcon}
					iconAlt='menuIcon'
					action={handleMobileMenu}
					className='menuBtn'
					color='white'
				/>
			</header>
			<main className='deviceDetailsMobile'>
				<div className='titleAndToggler'>
					<h1>{t(`devices.detailsTitles.toggler.${currentDeviceTab}`)}</h1>
					<ButtonGroup aria-label='deviceStatisticToggler'>
						<ButtonComponent
							title={t('devices.detailsTitles.toggler.players')}
							className='playersToggle'
							color={`${currentDeviceTab === 'players' ? 'blue' : 'white'}`}
							action={() => setCurrentDeviceTab('players')}
						/>
						<ButtonComponent
							title={t('devices.detailsTitles.toggler.history')}
							className='historyToggle'
							color={`${currentDeviceTab === 'history' ? 'blue' : 'white'}`}
							action={() => {
								// handleFetchHistory(deviceId ?? '');
								// refetch();
								setCurrentDeviceTab('history');
							}}
							// disabled={!user?.restrictions?.includes('can_view_report_daily')}
						/>
					</ButtonGroup>
				</div>
				{currentDeviceTab === 'players' &&
					(showLoader ? (
						<div className='detailsTabs noPlaces'>
							<LineWave
								visible={true}
								height='100'
								width='100'
								color='#4fa94d'
								ariaLabel='line-wave-loading'
								wrapperStyle={{}}
								wrapperClass='lineWave'
								firstLineColor='#EB2F2F'
								middleLineColor='#3BBB26'
								lastLineColor='#3262DE'
							/>
						</div>
					) : (
						<div className='places'>
							{(currentDetails?.places?.length ?? 0) > 0
								? currentDetails?.places?.map((place, index) => (
										<DeviceDetailTab
											key={index}
											{...place}
											setCurrentDetailsUndefined={() =>
												setCurrentDetails(undefined)
											}
											refetchDetails={refetchDetails}
										/>
								  ))
								: (currentDetails?.places?.length ?? 0) === 0 && (
										<div className='noPlaces'>
											{t('devices.detailsTab.noPlaces.inProgress')}
											<br />
											{t('devices.detailsTab.noPlaces.willBeDisplayed')}
										</div>
								  )}
						</div>
					))}
				{currentDeviceTab === 'history' &&
					(historyFetching ? (
						<div className='detailsTabs noPlaces'>
							<LineWave
								visible={true}
								height='100'
								width='100'
								color='#4fa94d'
								ariaLabel='line-wave-loading'
								wrapperStyle={{}}
								wrapperClass='lineWave'
								firstLineColor='#EB2F2F'
								middleLineColor='#3BBB26'
								lastLineColor='#3262DE'
							/>
						</div>
					) : (
						<>
							{!user?.restrictions?.includes(
								/* 'can_view_report_daily_total' */ 'disable_daily_total'
							) && (
								<div className='detailsHeader'>
									<DailyStatisticsTab
										title={t('devices.detailsHeaders.deposit')}
										count={((dailyDeposit ?? 0) / 100).toFixed(2)}
										deposit={true}
										// percent={24}
									/>
									<DailyStatisticsTab
										title={t('devices.detailsHeaders.withdraw')}
										count={(-(dailyWithdraw ?? 0) / 100).toFixed(2)}
										deposit={false}
										// percent={12}
									/>
									{/* <DailyStatisticsTab
										title={t('devices.detailsHeaders.delta')}
										count={((dailyDelta ?? 0) / 100).toFixed(2)}
										deposit={dailyDelta > 0}
										// percent={12}
									/> */}
								</div>
							)}
							<div className='places'>
								{(userHistory?.length ?? 0) > 0 ? (
									userHistory?.map((place, index) => (
										<DeviceHistoryTab key={index} {...place} />
									))
								) : (
									<div className='noPlaces'>
										{t('devices.detailsTab.noPlaces.history')}
									</div>
								)}
							</div>
						</>
					))}
			</main>
		</>
	);
};

export default DeviceDetailsMobile;
