const pt = {
	translation: {
		toasts: {
			error: {
				noPage: 'A página que você está procurando não existe',
				unauthorized: 'Você precisa fazer login primeiro',
				timeout:
					'Tempo de operação esgotado. Algo deu errado no servidor. Por favor, entre em contato com o suporte técnico para obter ajuda.',
				unknown:
					'Erro desconhecido. Por favor, entre em contato com o suporte técnico para obter ajuda.',
				failedToCopy: 'Falha ao copiar {{string}} para a área de transferência',
				cookieExpired: 'Sua sessão expirou. Por favor, faça login novamente.',
				forbidden: 'Proibido',
				overdraft:
					'Limite de cheque especial da conta.<br />Saldo: {{balance}}<br />Cheque especial: {{overdraft}}',
				notEnough: `Desculpe, o saldo do jogador não é suficiente para esta operação`,
				noDevices: 'Nenhum dispositivo disponível',
				transactionLimit: {
					min: 'Ops! O valor está abaixo do limite mínimo permitido.<br />Valor mínimo: {{min}}',
					max: 'Ops! Você excedeu o limite máximo de transação.<br />Valor máximo: {{max}}',
				},
				blocked: 'Usuário bloqueado',
			},
			success: {
				deposit: 'Depósito realizado com sucesso',
				withdraw: 'Retirada realizada com sucesso',
				cashOut: 'Dinheiro sacado com sucesso',
				succeedToCopy: '{{string}} copiado para a área de transferência',
				changedPassword: 'Senha alterada com sucesso',
			},
			requestErrors: {
				existingEmail: 'Já existe um usuário com este e-mail',
				serverError:
					'Erro interno do servidor. Pedimos desculpas, já estamos trabalhando nisso',
				userNotFound:
					'Usuário não encontrado, por favor, verifique se o login e/ou senha estão corretos',
				incorrectEmail: 'Verifique a ortografia do seu e-mail',
				successful:
					'Cadastro realizado com sucesso! Você pode acessar sua conta pessoal usando seu nome de usuário e senha',
				noAccount: 'O usuário não tem uma conta',
			},
		},
		modals: {
			addDevice: {
				titles: {
					title: 'Adicionar dispositivo',
					description: 'Para adicionar um dispositivo, insira o código PIN',
				},
				placeholders: {
					pin: 'PIN',
				},
				activate: 'Ativar',
				success: 'O dispositivo {{string}} foi adicionado com sucesso',
				error:
					'Erro ao adicionar o dispositivo. Verifique se o código PIN está correto',
			},
			cashManage: {
				manage: 'Gerenciar',
				player: 'Jogador #',
				color: 'Cor',
				balance: 'Saldo',
				finalBonus: 'Bônus final disponível',
				quickNumbers: 'Números rápidos',
				sure: 'Tem certeza de que deseja',
				for: 'para o',
				burn: 'o bônus final será perdido',
				btns: {
					cashOut: 'Sacar',
					deposit: 'Depositar',
					withdraw: 'Retirar',
					cancel: 'Cancelar',
				},
				placeholders: {
					amount: '0,00',
				},
			},
			editDevice: {
				approvementBlock: {
					title: 'Salvando alterações no dispositivo',
					description:
						'Tem certeza de que deseja salvar as edições do Dispositivo <span>{{string}}</span>?',
					btns: {
						back: 'Voltar',
						save: 'Salvar',
					},
				},
				mainForm: {
					device: 'Dispositivo',
					edit: 'editar',
					id: 'ID',
					uuid: 'UUID',
					gameId: 'ID do jogo',
					name: 'Nome',
					positon: 'Posição',
					btns: {
						cancel: 'Cancelar',
						save: 'Salvar',
					},
					placeholders: {
						name: 'Nome do dispositivo',
					},
				},
			},
			lock: {
				title: 'La caja está bloqueada',
				description: `Para desbloquear, ingresa la contraseña del cajero o cierra la sesión de tu cuenta actual`,
				forgot: 'Si olvidaste tu contraseña, contacta al administrador',
				logOut: 'Cerrar sesión',
			},
			statistic: {
				totalDelta: 'Delta total',
				totalIncome: 'Renda total',
				totalOutcome: 'Saída total',
				totalOperations: 'Operações totais',
				select: `Selecione as datas e pressione 'Aplicar'`,
				labels: {
					from: 'De:',
					to: 'Para:',
				},
				placeholders: {
					selectDate: `Selecione a data`,
				},
				btns: {
					closeBtn: 'Fechar',
					saveBtn: 'Aplicar',
					resetBtn: 'Redefinir',
				},
			},
			changePassword: {
				title: 'Alterar senha',
				old: 'Senha antiga',
				new: 'Nova senha',
				repeat: 'Repetir nova senha',
				placeholders: {
					old: 'Digite a senha antiga',
					new: 'Digite a nova senha',
					repeat: 'Repita a nova senha',
				},
				errors: {
					required: 'A senha {{type}} é obrigatória',
					max: 'A senha {{type}} é muito longa',
					min: 'A senha {{type}} é muito curta',
					mismatch: 'A nova senha e a repetida não coincidem',
				},
			},
		},
		navbar: {
			titles: {
				authorization: 'Autorização',
				id: 'ID',
				devices: 'Dispositivos',
				history: 'Histórico',
				statistic: 'Estatísticas',
				profile: 'Perfil',
				yourId: 'Seu ID',
			},
			btns: {
				lock: 'Bloquear',
				logout: 'Sair',
			},
			languages: {
				title: 'Idiomas',
				en: 'Inglês',
				es: 'Espanhol',
				pt: 'Português',
			},
			gamePlatform: 'Plataforma de Jogos',
			float: 'Flutuante',
		},
		authorization: {
			serverError:
				'Desculpe, ocorreu um erro interno no servidor. Tente novamente mais tarde.',
			loadingUser: 'Carregando usuário...',
			placeholders: {
				login: 'Usuário',
				password: 'Senha',
			},
			rememberMeCheck: 'Lembrar de mim',
			loginBtn: 'Entrar',
			errors: {
				login: {
					required: 'Digite seu usuário',
					maxLength: 'O usuário é muito longo',
					minLength: 'O usuário é muito curto',
				},
				password: {
					required: 'Digite sua senha',
					maxLength: 'A senha é muito longa',
					minLength: 'A senha é muito curta',
				},
			},
			helpLinks: {
				forgotPassword: 'Esqueceu sua senha?',
				services: {
					title: 'Atendimento técnico 24 horas:',
					telegram: 'Telegram',
				},
			},
		},
		devices: {
			devicesList: 'Lista de Dispositivos',
			deviceTypeToggler: {
				visible: 'Visível',
				hidden: 'Oculto',
			},
			addDevice: '+ Adicionar dispositivo',
			device: 'Dispositivo',
			unknown: 'desconhecido',
			notSelected: 'não selecionado',
			devicesTitles: {
				name: 'Nome',
				id: 'ID',
				players: 'Jogadores',
				payment: 'Pagamento',
			},
			detailsHeaders: {
				deposit: 'Depósito em 24h',
				withdraw: 'Retirada em 24h',
				delta: 'Delta em 24h',
			},
			detailsTitles: {
				username: 'Nome de usuário',
				color: 'Cor',
				balance: 'Saldo',
				type: 'Tipo',
				transactionId: 'ID de Transação',
				player: 'Jogador',
				amount: 'Quantia',
				date: 'Data',
				toggler: {
					players: 'Jogadores',
					history: 'Histórico',
				},
			},
			detailsTab: {
				player: 'Jogador',
				manage: 'Gerenciar',
				noPlaces: {
					inProgress: 'Registro do dispositivo em andamento...',
					willBeDisplayed: 'Os jogadores serão exibidos ao final.',
					selectDevice: 'Selecionar dispositivo',
					history: 'O histórico está vazio',
				},
			},
		},
		history: {
			btns: {
				operationType: {
					all: 'Tudo',
					deposit: 'Depósito',
					withdraw: 'Retirada',
				},
			},
			titles: {
				deviceName: 'Nome do dispositivo',
				deviceId: 'ID do dispositivo',
				player: 'Jogador',
				transactionId: 'ID da transação',
				amount: 'Valor',
				type: 'Tipo',
				date: 'Data',
				print: 'Imprimir',
			},
			sortAndFilter: 'Ordenar e filtrar',
			sortByDate: 'Ordenar por data:',
			fromNew: 'Dos mais recentes para os mais antigos',
			fromOld: 'Dos mais antigos para os mais recentes',
			filterByDevice: 'Filtrar por dispositivo:',
		},
	},
};

export default pt;
