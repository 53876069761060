const es = {
	translation: {
		toasts: {
			error: {
				noPage: 'La página que buscas no existe',
				unauthorized: 'Primero necesitas iniciar sesión',
				timeout:
					'Tiempo de espera agotado. Algo salió mal en el servidor. Por favor, contacta al soporte técnico para obtener ayuda.',
				unknown:
					'Error desconocido. Por favor, contacta al soporte técnico para obtener ayuda.',
				failedToCopy: 'No se pudo copiar {{string}} al portapapeles',
				cookieExpired:
					'Tu sesión ha expirado. Por favor, inicia sesión de nuevo.',
				forbidden: 'Prohibido',
				overdraft:
					'Límite de sobregiro de cuenta.<br />Saldo: {{balance}}<br />Sobregiro: {{overdraft}}',
				notEnough: `Lo sentimos, el saldo del jugador no es suficiente para esta operación`,
				noDevices: 'No hay dispositivos disponibles',
				transactionLimit: {
					min: '¡Ups! El monto está por debajo del límite mínimo requerido.<br />Monto mínimo: {{min}}',
					max: '¡Ups! Has excedido el límite máximo de transacción.<br />Monto máximo: {{max}}',
				},
				blocked: 'Usuario bloqueado',
			},
			success: {
				deposit: 'Depósito realizado con éxito',
				withdraw: 'Retiro realizado con éxito',
				cashOut: 'Dinero retirado con éxito',
				succeedToCopy: '{{string}} copiado al portapapeles',
				changedPassword: 'Contraseña cambiada con éxito',
			},
			requestErrors: {
				existingEmail: 'Ya existe un usuario con este correo electrónico',
				serverError:
					'Error interno del servidor. Lo sentimos, ya estamos trabajando en ello',
				userNotFound:
					'Usuario no encontrado, verifica si tu usuario y/o contraseña son correctos',
				incorrectEmail: 'Revisa la ortografía de tu correo electrónico',
				successful:
					'¡Registro exitoso! Puedes acceder a tu cuenta personal con tu usuario y contraseña',
				noAccount: 'El usuario no tiene una cuenta',
			},
		},
		modals: {
			addDevice: {
				titles: {
					title: 'Agregar dispositivo',
					description: 'Para agregar un dispositivo, ingresa el código PIN',
				},
				placeholders: {
					pin: 'PIN',
				},
				activate: 'Activar',
				success: 'El dispositivo {{string}} se agregó con éxito',
				error:
					'Error al agregar el dispositivo. Verifica que el código PIN sea correcto',
			},
			cashManage: {
				manage: 'Gestionar',
				player: 'Jugador #',
				color: 'Color',
				balance: 'Saldo',
				finalBonus: 'Bono final disponible',
				quickNumbers: 'Números rápidos',
				sure: '¿Estás seguro de que quieres',
				for: 'para el',
				burn: 'el bono final será eliminado',
				btns: {
					cashOut: 'Retirar',
					deposit: 'Depositar',
					withdraw: 'Retirar',
					cancel: 'Cancelar',
				},
				placeholders: {
					amount: '0.00',
				},
			},
			editDevice: {
				approvementBlock: {
					title: 'Guardando cambios en el dispositivo',
					description:
						'¿Estás seguro de que deseas guardar los cambios del Dispositivo <span>{{string}}</span>?',
					btns: {
						back: 'Atrás',
						save: 'Guardar',
					},
				},
				mainForm: {
					device: 'Dispositivo',
					edit: 'editar',
					id: 'ID',
					uuid: 'UUID',
					gameId: 'ID del juego',
					name: 'Nombre',
					positon: 'Posición',
					btns: {
						cancel: 'Cancelar',
						save: 'Guardar',
					},
					placeholders: {
						name: 'Nombre del dispositivo',
					},
				},
			},
			lock: {
				title: 'La caja está bloqueada',
				description: `Para desbloquear, ingresa la contraseña del cajero o cierra la sesión de tu cuenta actual`,
				forgot: 'Si olvidaste tu contraseña, contacta al administrador',
				logOut: 'Cerrar sesión',
			},
			statistic: {
				totalDelta: 'Delta total',
				totalIncome: 'Ingreso total',
				totalOutcome: 'Salida total',
				totalOperations: 'Operaciones totales',
				select: `Seleccione las fechas y presione 'Aplicar'`,
				labels: {
					from: 'Desde:',
					to: 'Hasta:',
				},
				placeholders: {
					selectDate: 'Selecciona la fecha',
				},
				btns: {
					closeBtn: 'Cerrar',
					saveBtn: 'Aplicar',
					resetBtn: 'Restablecer',
				},
			},
			changePassword: {
				title: 'Cambiar contraseña',
				old: 'Contraseña antigua',
				new: 'Nueva contraseña',
				repeat: 'Repetir nueva contraseña',
				placeholders: {
					old: 'Introduce la contraseña antigua',
					new: 'Introduce la nueva contraseña',
					repeat: 'Repite la nueva contraseña',
				},
				errors: {
					required: 'La contraseña {{type}} es obligatoria',
					max: 'La contraseña {{type}} es demasiado larga',
					min: 'La contraseña {{type}} es demasiado corta',
					mismatch: 'La nueva contraseña y su repetición no coinciden',
				},
			},
		},
		navbar: {
			titles: {
				authorization: 'Autorización',
				id: 'ID',
				devices: 'Dispositivos',
				history: 'Historial',
				statistic: 'Estadísticas',
				profile: 'Perfil',
				yourId: 'Tu ID',
			},
			btns: {
				lock: 'Bloquear',
				logout: 'Cerrar sesión',
			},
			languages: {
				title: 'Idiomas',
				en: 'Inglés',
				es: 'Español',
				pt: 'Portugués',
			},
			gamePlatform: 'Plataforma de juegos',
			float: 'Flotante',
		},
		authorization: {
			serverError:
				'Lo sentimos, ocurrió un error interno en el servidor. Inténtalo de nuevo más tarde.',
			loadingUser: 'Cargando usuario...',
			placeholders: {
				login: 'Usuario',
				password: 'Contraseña',
			},
			rememberMeCheck: 'Recuérdame',
			loginBtn: 'Iniciar sesión',
			errors: {
				login: {
					required: 'Ingresa tu usuario',
					maxLength: 'El usuario es demasiado largo',
					minLength: 'El usuario es demasiado corto',
				},
				password: {
					required: 'Ingresa tu contraseña',
					maxLength: 'La contraseña es demasiado larga',
					minLength: 'La contraseña es demasiado corta',
				},
			},
			helpLinks: {
				forgotPassword: '¿Olvidaste tu contraseña?',
				services: {
					title: 'Servicio técnico 24/7:',
					telegram: 'Telegram',
				},
			},
		},
		devices: {
			devicesList: 'Lista de dispositivos',
			deviceTypeToggler: {
				visible: 'Visible',
				hidden: 'Oculto',
			},
			addDevice: '+ Agregar dispositivo',
			device: 'Dispositivo',
			unknown: 'desconocido',
			notSelected: 'no seleccionado',
			devicesTitles: {
				name: 'Nombre',
				id: 'ID',
				players: 'Jugadores',
				payment: 'Pago',
			},
			detailsHeaders: {
				deposit: 'Depósito en 24h',
				withdraw: 'Retiro en 24h',
				delta: 'Delta en 24h',
			},
			detailsTitles: {
				username: 'Nombre de usuario',
				color: 'Color',
				balance: 'Saldo',
				type: 'Tipo',
				transactionId: 'ID de Transacción',
				player: 'Jugador',
				amount: 'Cantidad',
				date: 'Fecha',
				toggler: {
					players: 'Jugadores',
					history: 'Historial',
				},
			},
			detailsTab: {
				player: 'Jugador',
				manage: 'Gestionar',
				noPlaces: {
					inProgress: 'Registro de dispositivo en progreso...',
					willBeDisplayed: 'Los jugadores se mostrarán al finalizar.',
					selectDevice: 'Seleccionar dispositivo',
					history: 'El historial está vacío',
				},
			},
		},
		history: {
			btns: {
				operationType: {
					all: 'Todo',
					deposit: 'Depósito',
					withdraw: 'Retiro',
				},
			},
			titles: {
				deviceName: 'Nombre del dispositivo',
				deviceId: 'ID del dispositivo',
				player: 'Jugador',
				transactionId: 'ID de la transacción',
				amount: 'Cantidad',
				type: 'Tipo',
				date: 'Fecha',
				print: 'Imprimir',
			},
			sortAndFilter: 'Ordenar y filtrar',
			sortByDate: 'Ordenar por fecha:',
			fromNew: 'De más recientes a más antiguos',
			fromOld: 'De más antiguos a más recientes',
			filterByDevice: 'Filtrar por dispositivo:',
		},
	},
};

export default es;
