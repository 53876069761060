import React, { useLayoutEffect, useState } from 'react';

import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import MobileMenuModal from './components/MobileMenuModal/MobileMenuModal';
import StatisticModal from './components/StatisticModal/StatisticModal';
import copyIcon from '../../../../../assets/icons/copyIcon.svg';
import devicesIcon from '../../../../../assets/icons/devicesIcon.svg';
// import gameController from '../../../../../assets/icons/gameControllerIcon.svg';
import historyIcon from '../../../../../assets/icons/historyIcon.svg';
// import lockIcon from '../../../../../assets/icons/lockIcon.svg';
import menuIcon from '../../../../../assets/icons/menuIcon.svg';
import statisticIcon from '../../../../../assets/icons/statisticIcon.svg';
// import titleArrow from '../../../../../assets/icons/titleArrow.svg';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux';
import ChangePasswordModal from '../../../../../pages/Devices/components/ChangePasswordModal/ChangePasswordModal';
import LockModal from '../../../../../pages/Devices/components/LockModal/LockModal';
import { baseAPI } from '../../../../../services/baseAPI';
import { userAPI } from '../../../../../services/UserService';
import { userSlice } from '../../../../../store/reducers/UserSlice';
import handleCopyToClipboard from '../../../../../utils/handleCopyToClipboard';
import showToastOnce from '../../../../../utils/showToastOnce';
import ButtonComponent from '../../../Button/ButtonComponent';
import Clock from '../../../Clock/Clock';
// import DropdownComponent from '../../../Dropdown/Dropdown';
// import { gamePlatforms } from '../../consts';
import Float from '../../../Float/Float';
import './styles.scss';

const CashierNavbar: React.FC = () => {
	const { user } = useAppSelector((state) => state.userReducer);
	const dispatch = useAppDispatch();
	const { deleteUser } = userSlice.actions;
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const trimmedPathname = pathname.slice(1);

	const [signOut] = userAPI.useSignOutMutation();

	const handleSignOut = async () => {
		try {
			const response = await signOut(user?.id ?? '').unwrap();
			if (response.ok) {
				navigate('/authorization');
				dispatch(deleteUser());
				return () => {
					dispatch(baseAPI.util.resetApiState());
				};
			}
		} catch (error: any) {
			if (error.status === 401) {
				navigate('/authorization');
				showToastOnce(
					'error',
					t('toasts.error.cookieExpired'),
					'cookieExpired'
				);
				return () => {
					dispatch(baseAPI.util.resetApiState());
				};
			}
		}
	};

	const [gamePlatform, setGamePlatform] = useState<string>('1');

	const isDesktop = useMediaQuery({ query: '(min-width: 1351px)' });
	const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1350 });

	const [showMobileMenu, setShowMobileMenu] = useState<boolean>(false);
	const handleMobileMenu = () => setShowMobileMenu(!showMobileMenu);

	const [showStatistic, setShowStatistic] = useState<boolean>(false);
	const handleShowStatistic = () => setShowStatistic(!showStatistic);

	const [showChangePasswordModal, setShowChangePasswordModal] =
		useState<boolean>(false);
	const handleChangePasswordModal = () =>
		setShowChangePasswordModal(!showChangePasswordModal);

	useLayoutEffect(() => {
		const checkJivoAPI = setInterval(() => {
			//@ts-ignore
			if (window.jivo_api) {
				if (!user?.restrictions?.includes('disable_support_widget')) {
					//@ts-ignore
					jivo_init();
				} else {
					//@ts-ignore
					jivo_destroy();
				}
				//@ts-ignore
				window.jivo_api.setContactInfo({
					name: `${user?.login} #${user?.id}`,
					email: '',
					phone: '',
					description: '',
				});
				//@ts-ignore
				window.jivo_api.setCustomData([
					{
						title: `User's ID`,
						key: `User's ID`,
						content: `${user?.id}`,
					},
				]);
				clearInterval(checkJivoAPI);
			}
		}, 100);

		// stop checking in 10 sec
		const timeout = setTimeout(() => clearInterval(checkJivoAPI), 10000);

		return () => {
			clearInterval(checkJivoAPI);
			clearTimeout(timeout);
		};
	}, [user]);

	return (
		<>
			<LockModal />
			<MobileMenuModal
				show={showMobileMenu}
				handler={handleMobileMenu}
				setGamePlatform={setGamePlatform}
				gamePlatform={gamePlatform}
			/>
			<StatisticModal show={showStatistic} handler={handleShowStatistic} />
			<ChangePasswordModal
				show={showChangePasswordModal}
				handler={handleChangePasswordModal}
			/>
			<div className={`titleGamePlatform ${!isDesktop ? 'reverse' : ''}`}>
				{/* {(isDesktop || isTablet) && (
					<DropdownComponent
						className='gamePlatformDropdown'
						icon={gameController}
						iconAlt='hieroglyphIcon'
						title={`${t('navbar.gamePlatform')} ${gamePlatform}`}
						items={gamePlatforms.map((platform) => ({
							title: platform,
							action: () => setGamePlatform(platform.slice(-1)),
						}))}
					/>
				)} */}
				{/* <img src={titleArrow} alt='titleArrow' className='titleArrow' /> */}
				<div className='timeTitle'>
					<Clock />
					<h1>{t(`navbar.titles.${trimmedPathname}`)}</h1>
				</div>
			</div>
			{isDesktop ? (
				<div className='timeNavbarBtnsProfile'>
					{/* <Clock /> */}
					<div className='navbarBtns'>
						<Float />
						<ButtonComponent
							title={t('navbar.titles.devices')}
							icon={devicesIcon}
							iconAlt='devicesIcon'
							className='devicesBtn'
							color='green'
							action={() => navigate('/devices')}
						/>
						{user?.restrictions?.includes('can_view_history_reports') && (
							<ButtonComponent
								title={t('navbar.titles.history')}
								icon={historyIcon}
								iconAlt='historyIcon'
								className='historyBtn'
								color='green'
								action={() => navigate('/history')}
								// disabled={true}
							/>
						)}
						<ButtonComponent
							title={t('navbar.titles.statistic')}
							icon={statisticIcon}
							iconAlt='statisticIcon'
							className='statisticBtn'
							color='green'
							action={handleShowStatistic}
						/>
						{/* <ButtonComponent
							// title={t('navbar.btns.lock')}
							icon={lockIcon}
							iconAlt='lockIcon'
							className='lockBtn'
							color='red'
							disabled={true}
							// action???
						/> */}
						<ButtonComponent
							title={t('navbar.btns.logout')}
							className='logoutBtn'
							color='white'
							action={handleSignOut}
						/>
					</div>
					<Link
						className='profile'
						/* to='/profile' */ to=''
						onClick={handleChangePasswordModal}>
						<p
							onClick={(e) => {
								e.preventDefault();
								e.stopPropagation();
								handleCopyToClipboard('ID', user?.id ?? '');
							}}>
							{t('navbar.titles.yourId')}: {user?.id}
							<img src={copyIcon} alt='copyIcon' />
						</p>
						<p className='name'>
							{(user?.name?.length ?? 0) > 0 ? user?.name : 'Cashier'}
						</p>
						<div className='avatar noAvatar'>
							{(user?.name?.length ?? 0) > 0
								? user?.name?.slice(0, 1).toUpperCase()
								: 'Cashier'.slice(0, 1).toUpperCase()}
						</div>
					</Link>
				</div>
			) : (
				<div className='dgaf'>
					{isTablet && (
						<>
							<Float />
							<Link
								className='profile'
								/* to='/profile' */ to=''
								onClick={handleChangePasswordModal}>
								<p
									onClick={(e) => {
										e.preventDefault();
										e.stopPropagation();
										handleCopyToClipboard('ID', user?.id ?? '');
									}}>
									{t('navbar.titles.yourId')}: {user?.id}
									<img src={copyIcon} alt='copyIcon' />
								</p>
								<p className='name'>
									{(user?.name?.length ?? 0) > 0 ? user?.name : 'Cashier'}
								</p>
								<div className='avatar noAvatar'>
									{(user?.name?.length ?? 0) > 0
										? user?.name?.slice(0, 1).toUpperCase()
										: 'Cashier'.slice(0, 1).toUpperCase()}
								</div>
							</Link>
						</>
					)}
					{!isTablet && (
						<p onClick={() => handleCopyToClipboard('ID', user?.id ?? '')}>
							{t('navbar.titles.yourId')}: {user?.id}
							<img src={copyIcon} alt='copyIcon' />
						</p>
					)}
					<Button className='mobileMenuBtn' onClick={handleMobileMenu}>
						<img src={menuIcon} alt='menuIcon' />
					</Button>
				</div>
			)}
		</>
	);
};

export default CashierNavbar;
