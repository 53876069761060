export const addDeviceNums = [
	'7',
	'8',
	'9',
	'4',
	'5',
	'6',
	'1',
	'2',
	'3',
	'0',
	'activate',
];
