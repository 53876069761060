/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { ThreeDots } from 'react-loader-spinner';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { baseAPI } from '../../../services/baseAPI';
import { cashierAPI } from '../../../services/CashierService';
import showToastOnce from '../../../utils/showToastOnce';
import './styles.scss';

const Float = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const isDesktop = useMediaQuery({ query: '(min-width: 1351px)' });
	const { t } = useTranslation();
	const { user } = useAppSelector((state) => state.userReducer);
	const {
		data: float,
		isFetching,
		error,
	} = cashierAPI.useFetchFloatQuery(user?.id ?? '', {
		skip: !user?.restrictions?.includes('can_cashier_view_account_balance'),
	});

	const overdraft = (float?.balance ?? 0) + (float?.overdraft ?? 0);

	const formattedFloat: number = user?.restrictions?.includes(
		'can_cashier_view_account_overdraft'
	)
		? overdraft
		: float?.balance ?? 0;

	useEffect(() => {
		if (error) {
			//@ts-ignore
			if (error.status === 401) {
				navigate('/authorization');
				showToastOnce(
					'error',
					t('toasts.error.cookieExpired'),
					'cookieExpired'
				);
				return () => {
					dispatch(baseAPI.util.resetApiState());
				};
			}
		}
	}, [error, navigate, t, dispatch]);

	return (
		<>
			{((user?.restrictions?.includes('can_cashier_view_account_balance') &&
				(float?.has_overdraft ?? false) &&
				float?.overdraft !== null &&
				!error) ||
				error) && (
				<div
					className='float'
					style={isDesktop ? { height: '42px' } : { height: '44px' }}>
					<p
						style={
							!isDesktop
								? { fontSize: '16px' }
								: isDesktop &&
								  String((formattedFloat / 100).toFixed(2)).length >= 16
								? { fontSize: '12px' }
								: {}
						}>
						{t('navbar.float')}
					</p>
					{isFetching || error ? (
						<ThreeDots
							visible={true}
							height='17'
							width='85'
							color='#3bbb26'
							radius='20'
							ariaLabel='three-dots-loading'
							wrapperClass='threeDots'
						/>
					) : (
						<h3
							className={`${
								formattedFloat > 0 ? 'green' : formattedFloat === 0 ? '' : 'red'
							}`}
							style={
								String((formattedFloat / 100).toFixed(2)).length >= 13 &&
								isDesktop
									? { fontSize: '12px' }
									: String((formattedFloat / 100).toFixed(2)).length >= 13
									? { fontSize: '16px' }
									: {}
							}>
							{(formattedFloat / 100).toFixed(2)}
						</h3>
					)}
				</div>
			)}
		</>
	);
};

export default Float;
