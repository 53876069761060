import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const baseAPI = createApi({
	reducerPath: 'baseAPI',
	keepUnusedDataFor: 5,
	baseQuery: fetchBaseQuery({
		baseUrl: `/api/v1`,
	}),
	endpoints: () => ({}),
});
